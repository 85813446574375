import React, { useState } from 'react';
import AlertMessage from './AlertMessage.js';
import { useParams } from "react-router-dom";
import Entity from './Entity.js';
import i18n from './i18n.js';
import y42 from './y42.js';

export default function Users(props) {
  const pageParams = useParams();
  const [alert, setAlert] = useState({open: false, severity: "info", message: ''});

  const fields = [
     { field: 'id', headerName: i18n.t('fID'), width: 70, type: 'text', isReadOnly: true, onChange: (() => {}) },
     { field: 'name', headerName: i18n.t('fName'), width: 230, type: 'text' },
     { field: 'fullName', headerName: i18n.t('fFullName'), width: 230, type: 'text' },
     { field: 'email', headerName: i18n.t('fEmail'), width: 230, type: 'text' },
     { field: 'account', headerName: i18n.t('fAccount'), width: 200, isNullable: false, type: 'ref', ref: {returnField: 'name', method: 'accounts'} },
     { field: 'role', headerName: i18n.t('fRole'), width: 180, isNullable: false, isReadOnly : true, type: 'ref', ref: {returnField: 'publicName', method: 'roles'} }  
  ];

  const buttons = [
    { 
      name: i18n.t('bClearPassword'), onClick : async () => { 
        const res = await y42.put("users/" + pageParams.id + "/clearPassword");
        if (res._status !== y42.ok)
            setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
        else
            setAlert({open: true, severity: "info", message: i18n.t("lPasswordCleared")});
      }
    }
  ];

  return (
    <>
      <AlertMessage open={alert.open} severity={alert.severity} message={alert.message}/>

      <Entity 
        title={i18n.t("tUsers")}
        props={props} 
        fields={fields}
        method="users" 
        path="users"
        buttons={buttons}
        filteredByField="account"/>
    </>
  );
}
