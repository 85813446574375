import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import Chart from './Chart.js';
import helper from './helper.js';
import i18n from './i18n.js';
import { ArrowUpward as ArrowUpIcon } from '@mui/icons-material';

export default function PrintCharts(props) {
	const gs = props.gs;
	let chartComponents = [];
  let chartGroups = [];

	let results = [];

	let cs = gs.game.currentStep - 1;
  let maxRounds = gs.game.stepsNumber;
	if (props.step !== null && props.step >= 0)
		cs = props.step * 1;
    
	let plId = gs.playerStatuses[0].player.id;
	if (props.playerId !== null && props.playerId >= 0)
		plId = props.playerId * 1;

    for (let i = 0; i <= Math.min(cs, Object.keys(gs.game.results).length); i++) {
      try {
        results.push(JSON.parse(gs.game.results[i]));
      } catch (e) 
      {

      }
    }

    if (results.length < cs + 1) return;

    if (!results[cs] || results[cs].charts === undefined) return;
    
    let rch = results[cs].charts;

    let fn = (x) => {
      return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    // if there're some charts
    if (rch) {
      for (let c = 0; c < rch.length; c++) {
        let chart = rch[c];
        // take next chart description
        let chartData = [];
        if (chart.group) {
          chartGroups.push(
              <Link key={"chartGrpA_" + c} href={"#chartGrp_" + c}>
                <Typography  sx={{mr: 2}} variant="button">
                  {chart.group + ""}
                </Typography>
              </Link>);
          chartComponents.push(
              <Box key={"chartGrp_" + c} sx={{pt: 6}}>
                  <a id={"chartGrp_" + c} />
                  <Typography variant="button">{chart.group + ""}</Typography>
              </Box>);
          continue;
        }

        if (chart.scope === "players") {
          // сравнение игроков на пузырьковой диаграмме
            for (let i = 0; i < gs.game.playersNumber; i++) {
                chartData.push({
                  x : results[cs].results[i][chart.x], 
                  y : results[cs].results[i][chart.y],
                  z : results[cs].results[i][chart.z],
                  label: {
                    tooltip : results[cs].results[i].playerName + " " + chart.zTitle + ": " + results[cs].results[i][chart.z],
                    color : helper.getColorByIndex(results[cs].results[i].playerId)
                  }
                });
            }
            chartComponents.push(
              <Chart key={"chart_" + c}
                type="scatter"
                xname={chart.xTitle} 
                yname={chart.yTitle} 
                zname={chart.zTitle}
                name={chart.title}
                data={chartData}/>);               
        } else
            // изменение какого-то значения со временем у одного игрока
            if (chart.scope === "time" && plId !== null && plId >= 0) {
                  let cy = [];
                  if (Array.isArray(chart.y))
                    cy = chart.y
                  else
                    cy = [chart.y];
                  for (let step = 0; step <= cs; step++) {
                    for (let i = 0; i < gs.game.playersNumber; i++) {
                      if (results[step].results[i].playerId === plId) {
                          let cd = {
                                      x : i18n.t("lStep") + " " + (step + 1) 
                                   };
                          let tt = "";
                          for (let cyi = 0; cyi < cy.length; cyi++) {
                            cd[cy[cyi]] = results[step].results[i][cy[cyi]];
                            if (chart.yTitle && Array.isArray(chart.yTitle))
                                tt += "\n" + chart.yTitle[cyi] + ": " + cd[cy[cyi]];
                            else
                                tt = "\n" + chart.yTitle + ": " + cd[cy[cyi]];
                          };
                          cd.label = {tooltip : tt};
                          //cd.color = helper.getColorByIndex(results[step].results[i].playerId);
                          chartData.push(cd);
                          break;
                      }
                    }
                  }
                  chartComponents.push(
                    <Chart key={"chart_" + c}
                      type={(chart.type && chart.type !== "")?chart.type:"line"}
                      xname={chart.xTitle} 
                      yname={chart.yTitle} 
                      name={chart.title}
                      colorScheme={chart.colorScheme}
                      color={"#8D805B"}
                      yFields={chart.y}
                      data={chartData}
                      rounds={cs}
                      maxRounds={maxRounds}/>);
            } else 
            if (chart.scope === "radar" && plId !== null && plId >= 0) {
                  let cr = chart.r; // должен быть массивом
                  for (let i = 0; i < gs.game.playersNumber; i++) {
                    if (results[cs].results[i].playerId === plId) {
                        for (let cri = 0; cri < cr.length; cri++) {
                          let cd = {};
                          let tt = "";
                          cd["key"] = chart.rTitle[cri];
                          cd["value"] = results[cs].results[i][cr[cri]];
                          tt += "\n" + chart.rTitle[cri] + ": " + cd[cr[cri]];
                          cd.label = {tooltip : tt};
                          cd.color = helper.getColorByIndex(results[cs].results[i].playerId);
                          chartData.push(cd);
                        };
                        break;
                    }
                  }
                  chartComponents.push(
                    <Chart key={"chart_" + c}
                      type={"radar"}
                      rname={chart.rTitle} 
                      name={chart.title}
                      color={"#8D805B"}
                      rFields={chart.r}
                      colorScheme={chart.colorScheme}
                      data={chartData}/>);
            } else
            if (chart.scope === "budget" && plId !== null && plId >= 0) {
                  let cin = chart.income; 
                  let cout = chart.expenses;
                  let colors = [];
                  let colorIn = helper.getColorByIndex2(chart.colorScheme, 0);
                  let colorOut = helper.getColorByIndex2(chart.colorScheme, 4);
                  let proficite = 0;
                  let deficite = 0;

                  for (let i = 0; i < gs.game.playersNumber; i++) {
                    if (results[cs].results[i].playerId === plId) {
                        //chartData = [["From", "To", "Weight"]];
                        chartData = [[
                          {type:'string', role:'domain', label: 'From'},
                          {type:'string', role:'domain', label: 'To'},
                          {type:'number', role:'data', label: 'Weight'},
                          {type:'string', role:'tooltip', label: 'Tooltip'}
                        ]];
                        for (let cri = 0; cri < cin.length; cri++) {
                          let d = results[cs].results[i][cin[cri]];
                          if (d <= 0) {
                            proficite -= d;
                            chartData.push([chart.incomeTitle[cri] + ": " + fn(d), " ", 0, fn(d)]);
                          } else {
                            chartData.push([chart.incomeTitle[cri] + ": " + fn(d), " ", d, fn(d)]);
                          }
                        };
                        colors.push(colorIn);
                        
                        for (let cri = 0; cri < cout.length; cri++) {
                          let d = results[cs].results[i][cout[cri]];
                          if (d <= 0) {
                            deficite -= d;
                            chartData.push([" ", chart.expensesTitle[cri] + ": " + fn(d), 0, fn(d)]);
                          } else {
                            chartData.push([" ", chart.expensesTitle[cri] + ": " + fn(d), d, fn(d)]);
                          }
                          colors.push(colorOut);
                        };
                        break;
                    }
                  }

                  if (deficite - proficite > 0)
                    chartData.push([i18n.t("tDeficite") + ": " + fn(deficite - proficite), " ", deficite - proficite, fn(deficite - proficite)]);
                  else
                    if (proficite - deficite > 0)
                      chartData.push([" ", i18n.t("tProficite") + ": " + fn(proficite - deficite), proficite - deficite, fn(proficite - deficite)]);

                  chartComponents.push(
                    <Chart key={"chart_" + c}
                      type={"budget"}
                      rname={chart.rTitle} 
                      name={chart.title}
                      color={"#8D805B"}
                      rFields={chart.r}
                      colorScheme={chart.colorScheme}
                      colors={colors}
                      data={chartData}/>);
            } else
            // изменение какого-то значения со временем у всех игроков
            if (chart.scope === "generalTime") {
              let cy = [];
              if (Array.isArray(chart.y))
                cy = chart.y
              else
                cy = [chart.y];
              for (let step = 0; step <= cs; step++) {
                let cd = {
                            x : i18n.t("lStep") + " " + (step + 1) 
                         };
                let tt = "";
                for (let cyi = 0; cyi < cy.length; cyi++) {
                  let yAxis = cy[cyi].split(".");
                  let r = 0;
                  if (yAxis.length === 1)
                    r = results[step][chart.y];
                  else
                    r = results[step][yAxis[0]][yAxis[1]];
                  cd[cy[cyi]] = r;

                  if (chart.yLabel && Array.isArray(chart.yLabel))
                      tt += "\n" + chart.yLabel[cyi] + ": " + r;
                  else
                      tt = "\n" + chart.yLabel + ": " + r;
                };
                cd.label = {tooltip : tt};
                chartData.push(cd);
              }
              chartComponents.push(
                <Chart key={"chart_" + c}
                  type={(chart.type && chart.type !== "")?chart.type:"line"}
                  xname={chart.xTitle} 
                  yname={chart.yTitle} 
                  name={chart.title}
                  yFields={chart.y}
                  colorScheme={chart.colorScheme}
                  color={"#8D805B"}
                  data={chartData}
                  rounds={cs}
                  maxRounds={maxRounds}/>);
            }

            chartComponents.push(
                <Link href="#reportsTop" key={"uplink_"+c}>
                  <ArrowUpIcon/>
                </Link>);
        }
    }

    return (
    	<>
        <a id={"reportsTop"}/>
        <Box sx={{marginTop: 10}}>
      		<Typography variant="button">
      			{i18n.t("tCharts")}
      		</Typography>
        </Box>
        {chartGroups}
	    	{chartComponents}
    	</>
    );
}