import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Typography, Button, Grid, MenuItem, Tabs, Tab, Box, Chip, Snackbar, Alert} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/styles';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import './../assets/css/md.css';
import soundStatus from "./../assets/snd/attention.wav";
import soundNewRound from "./../assets/snd/newround.wav";
import soundPreAlert from "./../assets/snd/prealert.wav";
import MoveForm from './MoveForm.js';
import LittleCard from './LittleCard.js';
import Countdown from './Countdown.js';
import Timer from './Timer.js';
import PrintCharts from './PrintCharts.js';
import helper from './helper.js';
import Loading from './Loading.js';
import Breadcrumbs from './Breadcrumbs.js';
import Progress from './Progress.js';
//import { BarChart, Bar, XAxis, YAxis, ReferenceLine  } from 'recharts';
import i18n from './i18n.js';
import y42 from './y42.js';
import { SportsEsports as GameIcon } from '@mui/icons-material';
import { Alarm as TimeIcon } from '@mui/icons-material';
import { Portrait as ProfileIcon } from '@mui/icons-material';
import { Person as PlayerIcon } from '@mui/icons-material';
import { DoubleArrow as StepIcon } from '@mui/icons-material';
import { Warning as MoveIcon } from '@mui/icons-material';
import { Check as DoneIcon } from '@mui/icons-material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import theme from './../theme.js';

const Sign = styled(Box)(
  ({ theme }) => ({
    marginRight: "5px",
    marginLeft: 0,
    marginTop: "3px",
    marginBottom: 0,
    borderRadius: "3px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    flexShrink: 0
  }),
);


const BoxContent = styled(Box)(
  ({ theme }) => ({
  }),
);

const BoxSm = styled(Box)(
  ({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: "row",
      justifyContent: "right",
    },
    position: "fixed",
    zIndex: 1500,
    width: "100%",
    padding: theme.spacing(2),
  }),
);

const chipSx = {
  borderRadius: "6px",
  mr: theme.spacing(1),
  mb: theme.spacing(1)
};

const countDownSmSx = {
  [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
  [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
};

const countDownSx = {
  [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
  [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
}

export default function RunGamePlayer(props) {
  const pageParams = useParams();

  const [alert, setAlert] = useState({open: false, severity: "info", message: ''});
  const [tab, setTab] = useState(0);
  const [inProcessOfSending, setInProcessOfSending] = useState(false);

  const tabCodes = ["res", "sit", "mov"];


  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
    let tabCode = 0;
    if (gs.game.currentStep === 0)
      tabCode = newTab + 1;
    else
      tabCode = newTab;
    y42.sendAction("Switch tab", player.id, gs.game.currentStep, tabCode, tabCodes[tabCode]);
    if (tabCodes[tabCode] === "res")
      y42.sendAction("Read results", player.id, gs.game.currentStep, selectedStep, "");
    if (tabCodes[tabCode] === "sit")
      y42.sendAction("Read situation", player.id, gs.game.currentStep, stnSelectedStep, "");
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{p: 3}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const [gs, setGs] = useState({});
  const [player, setPlayer] = useState({});
  const [moveParams, setMoveParams] = useState({});
  const [report, setReport] = useState("");
  const [progresses, setProgresses] = useState(null);
  const [progressValues, setProgressValues] = useState(null);
  const [selectedStep, setSelectedStep] = useState(0);
  const [stnSelectedStep, setStnSelectedStep] = useState(0);
  //const [stepsList, setStepsList] = useState([]);
  const [state, setState] = useState({isLoaded : false, isLoading : false});
  const [move, setMove] = useState(null);
  const [prevMove, setPrevMove] = useState(null);
  const [defMove, setDefMove] = useState(null);
  var error = false;

  useEffect(() => {
    async function fetch() {
      setState({isLoaded : false, isLoading : true});
      const res = await y42.get("games/" + pageParams.id + "/status/player");
      if (res._status !== y42.ok) {
        setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
      } else {
        if (res.game.currentStep === null)
          res.game.currentStep = 0;
        const res1 = await y42.get("players/byCurrentUserAndGame", res.game.id);
        if (res1._status !== y42.ok) {
          setAlert({open: true, severity: "error", message: y42.getMessage(res1._status, res1.message)});
        } else {
          for (let idx = 0; idx < Object.keys(res1.moves).length; idx++) {
            res1.moves[idx] = JSON.parse(res1.moves[Object.keys(res1.moves)[idx]]);
          }
          const res2 = await y42.get("games/" + res.game.id + "/moveParams");
          if (res2._status !== y42.ok) {
            setAlert({open: true, severity: "error", message: y42.getMessage(res2._status, res2.message)});
          } else {
            if (res.game.currentStep > 0) {
              const res3 = await y42.get("games/" + res.game.id + "/playerReport/" + res.playerStatuses[0].player.id + "/" + (res.game.currentStep - 1));
              if (res3._status !== y42.ok) {
                setAlert({open: true, severity: "error", message: y42.getMessage(res3._status, res3.message)});
              } else {
                setReport(res3.report);
              }
            }
            res.timeLoaded = new Date();
            setGs(res);

            setPlayer(res1);

            let mparams = res2;
            if (Array.isArray(res2)) {
                mparams = res2[0][1];
                for (let idx = 0; idx < res2.length; idx++) {
                    if (res2[idx][0] === res1.profile) {
                      mparams = res2[idx][1];
                      break;
                    }
                }
            }
            setMoveParams(mparams);
            if (res.playerStatuses && (res.playerStatuses[0].isPlayerStepFinished || res.isGameEnded || !res.isGameStarted)) {
              setMove(res1.moves[res.game.currentStep]);
              if (res.game.currentStep > 0) {
                setPrevMove(res1.moves[res.game.currentStep - 1]);
              }
            }
            else {
              let mv = {};
              Object.keys(mparams).map((el) => {mv[el] = mparams[el].def; return el});
              setDefMove(mv);
              setMove(mv);
              if (res.game.currentStep > 0) {
                setPrevMove(res1.moves[res.game.currentStep - 1]);
              } else
                setPrevMove(mv);
            }
            let steps = [];
            if (res.game.currentStep > 0)
              for (let i = res.game.currentStep - 1; i >= 0; i--) {
                steps.push(<MenuItem key={i} value={i}>
                              {i18n.t("lStep") + " " + (i + 1)}
                           </MenuItem>);
              }
            //setStepsList(steps);
            setSelectedStep(res.game.currentStep - 1);
            if (res.game.currentStep < res.game.stepsNumber)
              setStnSelectedStep(res.game.currentStep);
            else
              setStnSelectedStep(res.game.stepsNumber - 1);
            if (res.game.currentStep > 0) {
              const gres = JSON.parse(res.game.results[res.game.currentStep - 1]);
              setProgresses(gres.progress);
              setProgressValues(gres);
            }
            setState({isLoaded : true, isLoading : false});
            y42.sendAction("Read results", res1.id, res.game.currentStep, selectedStep, "");
          }
        }
      }
    }

    if (state.isLoaded || state.isLoading)
      return;

    fetch();
  }, [pageParams.id, state, selectedStep]);

  const fetch2 = async function () {
    if (state.isLoading) return;
    const res = await y42.get("games/" + pageParams.id + "/status/test");
    if (res._status !== y42.ok) {
      return null;
    } else {
      if ((gs.isGameStarted !== res.isGameStarted) ||
          (gs.isGameEnded !== res.isGameEnded) ||
          (gs.game.currentStep !== res.game.currentStep) ||
          (gs.isGamePaused !== res.isGamePaused)) {
        
        const res = await y42.get("games/" + pageParams.id + "/status/player");
        
        if (res._status !== y42.ok) return null;

        res.timeLoaded = new Date();
        setGs(res);

        if (gs.game.currentStep !== res.game.currentStep && res.game.currentStep >= 0) {
            const res1 = await y42.get("players/byCurrentUserAndGame", res.game.id);
            if (res1._status !== y42.ok) {
                setAlert({open: true, severity: "error", message: y42.getMessage(res1._status, res1.message)});
            } else {
                for (let idx = 0; idx < Object.keys(res1.moves).length; idx++) {
                  res1.moves[idx] = JSON.parse(res1.moves[Object.keys(res1.moves)[idx]]);
                }
                const res2 = await y42.get("games/" + res.game.id + "/moveParams");
                if (res2._status !== y42.ok) {
                    setAlert({open: true, severity: "error", message: y42.getMessage(res2._status, res2.message)});
                } else {
                  let mparams = res2;
                  if (Array.isArray(res2)) {
                      mparams = res2[0][1];
                      for (let idx = 0; idx < res2.length; idx++) {
                          if (res2[idx][0] === res1.profile) {
                            mparams = res2[idx][1];
                            break;
                          }
                      }
                  }
                  setMoveParams(mparams); 
                               
                  if (res.playerStatuses && (res.playerStatuses[0].isPlayerStepFinished || res.isGameEnded || !res.isGameStarted)) {
                    setMove(res1.moves[res.game.currentStep]);
                  }
                  else {
                    let mv = {};
                    Object.keys(mparams).map((el) => {mv[el] = mparams[el].def; return el});
                    setDefMove(mv);
                    setMove(mv);
                    if (res.game.currentStep > 0) {
                      setPrevMove(res1.moves[res.game.currentStep - 1]);
                    } else
                      setPrevMove(mv);
                  }
                  
                }
            }
            const res3 = await y42.get("games/" + res.game.id + "/playerReport/" + res.playerStatuses[0].player.id + "/" + (res.game.currentStep - 1));
            if (res3._status === y42.ok) {
              setReport(res3.report);
              setSelectedStep(res.game.currentStep - 1);
              if (res.game.currentStep < res.game.stepsNumber)
                setStnSelectedStep(res.game.currentStep);
              else
                setStnSelectedStep(res.game.stepsNumber - 1);

              let steps = [];
              for (let i = res.game.currentStep - 1; i >= 0; i--) {
                steps.push(<MenuItem key={i} value={i}>
                              {i18n.t("lStep") + " " + (i + 1)}
                           </MenuItem>);
              }
              //setStepsList(steps);
              const gres = JSON.parse(res.game.results[res.game.currentStep - 1]);
              setProgresses(gres.progress);
              setProgressValues(gres);
              y42.sendAction("Read results", player.id, gs.game.currentStep, selectedStep, "");
            }
        }
        setAlert({open: true, severity: "warning", message: i18n.t("msgGameStatusChanged")});
        y42.playSound(soundStatus);
      }
    }
  }

  // const sleep = (ms) => {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }

  const sendMove = async () => {
    if (!player && !player.id)
      return;
    if (error || move == null) {
       setAlert({open: true, severity: "error", message: i18n.t("errThereAreErrorsOnSave")});
       //setMove(null); 
       return;
    }
    setInProcessOfSending(true);

    //await sleep(2000);
    y42.post("players/" + player.id + "/sendMove", "", JSON.stringify(move))
      .then((res) => {
        if (res._status !== y42.ok) {
          setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
          setInProcessOfSending(false);
        } else
        {
          setAlert({open: true, severity: "info", message: i18n.t("lMovePostedSuccessfully")});
          setState({isLoaded : false, isLoading : false});
          setInProcessOfSending(false);
        }
      });   
    let currentTime = new Date();
    let timeDiff = "";
    if (gs.isGamePaused)
      timeDiff = "paused";  
    else
      if (gs.timeLoaded === null)
        timeDiff = "unknown";
      else {
        /*
        console.log(gs.secondsForStepLeft);
        console.log(currentTime);
        console.log(timeLoaded);*/
        timeDiff = (gs.secondsForStepLeft - Math.round(Math.abs(currentTime - gs.timeLoaded) / 1000)).toString();
      }

    y42.sendAction("Send move", player.id, gs.game.currentStep, timeDiff, "");
  }

  const moveChange = async (data, index, errors, registerAction) => {
    if (registerAction) 
      y42.sendAction("Change move", player.id, gs.game.currentStep, 0, "");
    error = !(((Array.isArray(errors) && errors.length === 0) || errors === "" || errors === undefined || errors === null));
    if (error) return;
    setMove(data);
  }

  const setError = () => {
    error = true;
  }

  async function fetchReport(newSelectedStep) {
      setReport(i18n.t("lLoading"));
      const res3 = await y42.get("games/" + gs.game.id + "/playerReport/" + player.id + "/" + newSelectedStep);
      if (res3._status !== y42.ok)
        setAlert({open: true, severity: "error", message: y42.getMessage(res3._status, res3.message)});
      else
        setReport(res3.report);
  }

  const handleSelectedStepChangeBtn = (newSelectedStep) => {
      y42.sendAction("Read results", player.id, gs.game.currentStep, newSelectedStep, "");
      setSelectedStep(newSelectedStep);
      fetchReport(newSelectedStep);            
      let gres = JSON.parse(gs.game.results[newSelectedStep]);
      setProgresses(gres.progress);
      setProgressValues(gres);
  }

  const handleStnSelectedChangeBtn = (newSelectedStep) => {
      setStnSelectedStep(newSelectedStep);
      y42.sendAction("Read situation", player.id, gs.game.currentStep, newSelectedStep, "");
  }

  const print = () => {
      window.open('/printReport/' + gs.game.id + '/' + player.id + '/' + selectedStep);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert({open: false, severity: "info", message: ''});
  };

  const handleShowMoveEffects = () => {
    y42.sendAction("Show move effects", player.id, gs.game.currentStep, 0, "");
  }

  const uiDisabled = inProcessOfSending || 
    (gs && gs.playerStatuses && ((gs.playerStatuses[0].isPlayerStepFinished && gs.game.moveModeStrict)|| gs.isGameEnded || !gs.isGameStarted));

  if (!state.isLoaded)
    return (<Loading/>);
  else {
    document.title = "Y42 " + gs.game.name;

    let chartComponents = [];
    if (gs.isGameStarted && gs.game.currentStep > 0) {
        chartComponents = (<PrintCharts gs={gs} step={selectedStep}/>);
    }

    let div = [<Sign key={"dv_"+gs.playerStatuses[0].player.id} sx={{backgroundColor: helper.getColorByIndex(gs.playerStatuses[0].player.id)}}/>, gs.playerStatuses[0].player.name];

    let cntdwn = null;

    if (gs.isGamePaused)
      cntdwn = null;
    else
      cntdwn = gs.secondsForStepLeft;

    let gres = null;
    if (stnSelectedStep > 0 && gs.game.results && gs.game.results[stnSelectedStep - 1])
      gres = JSON.parse(gs.game.results[stnSelectedStep - 1]);

    let selStep = stnSelectedStep;
    if (stnSelectedStep > gs.game.currentStep) {
      selStep = gs.game.currentStep;
      setStnSelectedStep(gs.game.currentStep);
    }

    let sdesc = [];
    let insights = [];
    
    if (gs.game.currentStep >= 0 /*&& gs.game.currentStep + 1 <= gs.game.stepsNumber*/) {
      if (gs.game.situationDescriptions && Object.keys(gs.game.situationDescriptions).length > gs.game.currentStep && gs.game.situationDescriptions[selStep] !== null)
        sdesc = gs.game.situationDescriptions[selStep].split("###");
      if (selStep > 0) {
        let results = gres;
        if (sdesc.length > 1 && results.insights && Array.isArray(results.insights) && results.insights.length > 0) {
          for (let insIndex = 0; insIndex < results.insights.length; insIndex += 1) {
            let sitIndex = results.insights[insIndex] + 1;
            if (sitIndex < sdesc.length)
              insights.push(sdesc[sitIndex]);
          }
        }
      }
    }

    const gameStatusText = gs.isGameStarted?(gs.isGameEnded?i18n.t("lGameEnded"):(gs.isGamePaused?i18n.t("lGamePaused"):i18n.t("lGameRunning"))):i18n.t("lGameNotStarted");
    const playerProfileText = ((player.profile !== "" && player.profile !== null) ? player.profile : "");
    let playerProfileTextShort = playerProfileText;
    if (playerProfileText.length > 20)
       playerProfileTextShort = playerProfileText.substr(0, 8) + ".." + playerProfileText.substr(playerProfileText.length - 9);
    const currentStepText = (gs.isGameStarted && !gs.isGameEnded)?((gs.game.currentStep + 1) + " / " + gs.game.stepsNumber):
                    (gs.isGameEnded?i18n.t("lAllStepsDone") + ": " + gs.game.stepsNumber:i18n.t("lGameNotStarted"));
    const playerMoveStatus = gs.playerStatuses[0].isPlayerStepFinished || gs.isGameEnded?i18n.t("lPlayerDone"):i18n.t("lPlayerThinking");

    const gameStatusAccented = gs.isGameStarted && !gs.isGameEnded;
    const playerMoveStatusAccented = !gs.playerStatuses[0].isPlayerStepFinished && !gs.isGameEnded;

    let roundButtons = [];

    if (gs.isGameStarted && gs.game.currentStep > 0) {
       for (let idx = 0; idx < gs.game.currentStep; idx++) {
          roundButtons.push(<Button key={"rbtn_"+idx}
                              color="primary" 
                              variant={idx === selectedStep ? "contained" : ""}
                              onClick={() => {handleSelectedStepChangeBtn(idx)}}>
                              {i18n.t("lStep") + " " + (idx + 1)}
                            </Button>)
       }
       for (let idx = gs.game.currentStep; idx < gs.game.stepsNumber; idx++) {
          roundButtons.push(<Button disabled key={"rbtn_"+idx}>
                              {i18n.t("lStep") + " " + (idx + 1)}
                            </Button>)
       }
    }

    let roundButtons2 = [];
    if (gs.isGameStarted && gs.game.currentStep >= 0) {
       for (let idx = 0; idx <= Math.min(gs.game.currentStep, gs.game.stepsNumber - 1); idx++) {
          roundButtons2.push(<Button key={"rbtn2_"+idx}
                              color="primary" 
                              variant={idx === stnSelectedStep ? "contained" : ""}
                              onClick={() => {handleStnSelectedChangeBtn(idx)}}>
                              {i18n.t("lStep") + " " + (idx + 1)}
                            </Button>)
       }
       for (let idx = gs.game.currentStep + 1; idx < gs.game.stepsNumber; idx++) {
          roundButtons2.push(<Button disabled key={"rbtn2_"+idx}>
                              {i18n.t("lStep") + " " + (idx + 1)}
                            </Button>)
       }
    }

    let moveForm = (
                    <MoveForm 
                      elements={moveParams}
                      values={move} 
                      onChange={moveChange}
                      disabled={uiDisabled}
                      gameId={pageParams.id}
                      callbackShowMoveEffects={handleShowMoveEffects}
                      callBackError={setError}
                      getInfluence={gs.game.moveInfluenceAvailable}
                   />
      );

    return (
    <>
        <Timer time={10} interval={10} callback={fetch2}/>
        <BoxContent>
          <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={handleClose} severity={alert.severity}>
              {alert.message}
            </Alert>
          </Snackbar>

          <BoxSm>
             <Button
                    variant="contained"
                    color="primary" 
                    onClick={() => {window.location.reload()}}
                    sx={{height: theme.spacing(4.5)}}
                    >
                    <RefreshIcon />
              </Button>
              <Chip icon={<TimeIcon />} 
                    label={
                      <Countdown
                          key={"cntdwn"+gs.game.id}
                          nkey={"cntdwn"+gs.game.id}
                          time={cntdwn}
                          code={gs.game.id + "_" + gs.game.currentStep}
                          nullText={i18n.t("lGamePaused")}
                          callback={() => {
                            y42.playSound(soundNewRound);
                            setState({isLoaded : false, isLoading : false})}
                          }
                          preAlert={60}
                          preAlertCallBack={() => {
                            y42.playSound(soundPreAlert);
                            setAlert({open: true, severity: "warning", message: i18n.t("mPreAlert")});
                          }}
                        sx={countDownSmSx}
                      />
                    } 
                    color="secondary" sx={{borderRadius: "6px", ml: 1, mr: 2, height: theme.spacing(4.5)}}/>
          </BoxSm>

          <Breadcrumbs recordTitles={[gs.game.name]}/>
          <Box sx={{paddingLeft: 2, paddingTop: 3}}>
            <Typography variant="button">
              {gs.game.name}
            </Typography>
          </Box>

          <Box sx={{paddingLeft: 3, paddingTop: 1}}>
            <Button
                color="secondary" 
                onClick={() => {window.location.reload()}}
                sx={{
                       [theme.breakpoints.down('sm')]: {
                          display: 'none'
                       }
                   }}
                >
                {i18n.t("bRefresh")}
            </Button>
          </Box>

          <Grid container direction="row" justify="flex-start" alignItems="center" 
                sx={{pl: 2, pt: 0, [theme.breakpoints.down('sm')]: {display: 'none'}}}>
            <LittleCard key="gs1" label={i18n.t("lGameStatus")}    text={gameStatusText}      isAccented={gameStatusAccented}/>
            <LittleCard key="gs4" label={i18n.t("lProfileLabel")}  text={playerProfileTextShort} title={playerProfileText}/>
            <LittleCard key="gs2" label={i18n.t("lCurrentStep")}   text={currentStepText}/>
            <LittleCard key="gs3" label={i18n.t("lStepTimeLeft")}>      
                {<Countdown
                          key={"cntdwn"+gs.game.id+"2"}
                          nkey={"cntdwn"+gs.game.id+"2"}
                          time={cntdwn}
                          code={gs.game.id + "_" + gs.game.currentStep}
                          nullText={i18n.t("lGamePaused")}
                          callback={() => {
                            y42.playSound(soundNewRound);
                            setState({isLoaded : false, isLoading : false})}
                          }
                          preAlert={60}
                          preAlertCallBack={() => {
                            y42.playSound(soundPreAlert);
                            setAlert({open: true, severity: "warning", message: i18n.t("mPreAlert")});
                          }}
                          sx={countDownSx}
                          />}
            </LittleCard>
            <LittleCard key="gs5" label={div}                      text={playerMoveStatus}    isAccented={playerMoveStatusAccented}/>
          </Grid>

          <Grid container direction="row" justify="flex-start" alignItems="center" 
                sx={{pt: 2, pl: 3, [theme.breakpoints.up('sm')]: {display: 'none'}}}>
            <Chip icon={<GameIcon />}     label={gameStatusText}    variant={gameStatusAccented?"default":"outlined"} color={gameStatusAccented?"secondary":"default"} sx={chipSx}/>
            <Chip icon={<StepIcon />}     label={currentStepText}   variant="outlined" sx={chipSx}/>
            <Chip icon={<PlayerIcon />}   label={player.name}       variant="outlined" sx={chipSx}/>
            <Chip icon={playerMoveStatusAccented?<MoveIcon/>:<DoneIcon/>}     label={playerMoveStatus}  variant={playerMoveStatusAccented?"default":"outlined"} color={playerMoveStatusAccented?"secondary":"default"} sx={chipSx}/>
            <Chip icon={<ProfileIcon />}  label={playerProfileTextShort} title={playerProfileText} variant="outlined" sx={chipSx}/>
          </Grid>

          <Box sx={{paddingLeft: 2, paddingTop: 4}}>
              <Tabs 
                key="tabs"
                value={tab} 
                onChange={handleChangeTab} 
                variant="standard"
              >
                {(gs.isGameStarted && gs.game.currentStep >0)?(<Tab key="resultstab" {...a11yProps(0)} label={i18n.t("lResults")} sx={{pl: 0, pr: 0, mr: 2, ml: 0, minWidth: 0}} />):""}
                <Tab key="situationtab" {...a11yProps(1)} label={i18n.t("lSituation")} sx={{pl: 0, pr: 0, mr: 0, ml: 0, minWidth: 0}}/>
                {(gs.isGameStarted && gs.game.currentStep < gs.game.stepsNumber)?(<Tab key="movetab" {...a11yProps(2)} label={i18n.t("lMove")}  sx={{pl: 0, pr: 0, mr: 0, ml: 2, minWidth: 0}}/>):""}
              </Tabs>
          </Box>
             
          <TabPanel value={tab} index={(gs.isGameStarted && gs.game.currentStep >0)?2:1}>
             <Box>
                  <Typography variant="button">
                    {i18n.t("lMoveForm") + " " + (gs.game.currentStep + 1)}
                  </Typography>&nbsp;&nbsp;
                  <Button color="secondary" onClick={() => {setMove(prevMove)}}>
                      {i18n.t("bCopyPrevMove")}
                  </Button>&nbsp;&nbsp;
                  <Button color="secondary" onClick={() => {setMove(defMove)}}>
                      {i18n.t("bResetMove")}
                  </Button>
                  {
                    moveForm
                  }
                  <Box sx={{pt: 2}}>
                      <Button variant="contained" 
                        color="primary" 
                        onClick={sendMove} 
                        disabled={uiDisabled}>{gs.playerStatuses[0].isPlayerStepFinished?i18n.t("bSendMoveAgain"):i18n.t("bSendMove")}</Button>
                  </Box> 
            </Box>
          </TabPanel>

          <TabPanel value={tab} index={(gs.isGameStarted && gs.game.currentStep >0)?1:0}>
              <Box sx={{ml: 1, pb: 2, display : gs.isGameStarted && gs.game.currentStep >= 0?'block':'none', maxWidth: theme.breakpoints.values.lg}}>
                {roundButtons2}
              </Box>
             {(gs.game.currentStep>=0) && (gs.isGameStarted) && (
                  <Box sx={{maxWidth: theme.breakpoints.values.md, ml: 1}}>
                    <Typography variant="button">
                      {i18n.t("lSituationForStep") + " " + (stnSelectedStep + 1)}
                    </Typography>
                    <Box>
                      <span className={"markdown-body"}>
                          <ReactMarkdown remarkPlugins={[gfm]}>
                            {sdesc[0]}
                          </ReactMarkdown>
                          {insights.map((insight) => {
                            return (
                              <>
                                <Typography variant="h3">{i18n.t("lInsight")}</Typography>
                                <ReactMarkdown remarkPlugins={[gfm]}>
                                  {insight}
                                </ReactMarkdown>
                              </>
                              );
                          })}
                      </span>
                    </Box>
                  </Box>
              )}
          </TabPanel>

          <TabPanel value={tab} index={0}>
              <Box sx={{ml: 1, pb: 2, display : gs.isGameStarted && gs.game.currentStep > 0?'block':'none', maxWidth: theme.breakpoints.values.lg}}>
                {roundButtons}
              </Box>
              <Box sx={{ml: 1, display : gs.isGameStarted && gs.game.currentStep > 0?'block':'none', maxWidth: theme.breakpoints.values.lg}}>
                  { progresses && progresses !== null &&
                      progresses.map((progress, idx) => {
                        for (let i = 0; i < gs.game.playersNumber; i++) {
                            if (progressValues.results[i].playerId === player.id)
                              return (<Progress
                                          key={"progress_" + idx}
                                          title={progress.title}
                                          value={progressValues.results[i][progress.valueField]} 
                                          max={progress.max} />); 
                        }
                        return null;
                      })
                  }
                <Grid container>
                    <Grid item sx={{flexGrow: 1}}>
                        <Typography variant="button">
                          {i18n.t("lReportForRound") + " " + (selectedStep>=0?selectedStep+1:"")}
                        </Typography>
                    </Grid>
                    <Grid item>
                      <Button 
                        color="secondary" 
                        sx={{ml: 2}}
                        onClick={print}
                        >
                        {i18n.t("bPrint")}
                      </Button>
                    </Grid>
                </Grid>
                <div className="markdown-body">
                  <ReactMarkdown remarkPlugins={[gfm]}>
                    {report}
                  </ReactMarkdown>
                  {(report !== i18n.t("lLoading")) ? chartComponents : null}
                </div>
             </Box>
          </TabPanel>
        </BoxContent>

    </>);
    }
}