import React from 'react';
import Entity from './Entity.js';
import i18n from './i18n.js';

export default function MyGames(props) {
  const fields = [
     { field: 'id', headerName: i18n.t('fID'), width: 70, type: 'text', isReadOnly: true },
     { field: 'name', headerName: i18n.t('fName'), width: 230, type: 'text', onChange: (() => {}) },
     { field: 'simulation', headerName: i18n.t('fSimulation'), width: 130, type: 'ref', isNullable: false, ref: {returnField: 'name', method: 'simulations'} },
  ];

  const buttons = [
    { name: i18n.t('bRunPlayerGame'), path : "./run"}
  ];

  return (
      <Entity 
        title={i18n.t("tMyGames")}
        props={props} 
        buttons={buttons}
        fields={fields}
        excludeStandardButtons={true}
        openCardIfSingle={false}
        method="mygames"
        />
  );
}
