import React from 'react';
import { Typography, CircularProgress, Box } from '@mui/material';
import i18n from './i18n.js';

export default function Loading(props) {
	return (
		<Box sx={{height: '100vh', display: "flex", flexFlow: "row nowrap", justifyContent: "center", alignItems: "center"}}>
			<CircularProgress />&nbsp;&nbsp;<Typography variant="body1">{i18n.t("lLoading")}</Typography>
		</Box>
	);
}
