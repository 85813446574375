import React from 'react';
import { Routes, Route } from "react-router-dom";
import BasicPage from './BasicPage.js';
import Simulations from './Simulations.js';   
import Users from './Users.js';
import Games from './Games.js';
import Players from './Players.js';
import RunGame from './RunGame.js';
import MyGames from './MyGames.js';
import RunGamePlayer from './RunGamePlayer.js';
import Dashboard from './Dashboard.js';
import i18n from './i18n.js';

export default function Manage() {
  const menuItems = [
  {icon: "home", text: i18n.t("mDashboard"), link: '/manage'},
  {icon: "people", text: i18n.t("mUsers"), link: '/manage/users'},
  {icon: "timer", text: i18n.t("mGames"), link: '/manage/games'}, 
  {icon: "sports_esports", text: i18n.t("mMyGames"), link: '/manage/mygames'},
  ];

  let inner = ( 
    <Routes>
      <Route key="manage"  path="/*" element={<Dashboard/>}/>
      <Route key="simulations" exact path="/simulations" element={<Simulations/>} />
      <Route key="users" exact path="/users" element={<Users/>} />
      <Route key="user" exact path="/users/:id" element={<Users/>} />
      <Route key="games" exact path="/games" element={<Games/>} />
      <Route key="game" exact path="/games/:id" element={<Games/>} />
      <Route key="players" exact path="/games/:fid/players" element={<Players/>} />
      <Route key="player" exact path="/games/:fid/players/:id" element={<Players/>} />
      <Route key="run" exact path="/games/:id/run" element={<RunGame/>} />
      <Route key="mygames" exact path="/mygames" element={<MyGames/>}/>
      <Route key="mygame" exact path="/mygames/:id" element={<RunGamePlayer/>}/>
    </Routes>
  );        


  return <BasicPage title={i18n.t('tManagementPanel')} menuItems={menuItems} routesComponents={inner} />;
}