import React, { useState, useRef } from 'react';
import { Typography } from '@mui/material';
import i18n from './i18n.js';

export default function Countdown(props) {
	const [timer, setTimer] = useState({code: props.code, timer: props.time});
    const [code, setCode] = useState(props.code);
    const [baseTime, setBaseTime] = useState(new Date());
    const [preAlerted, setPreAlerted] = useState(false);
    const [alerted, setAlerted] = useState(false);

    const cdRef = useRef(null);

    const isHidden = () => {
        if (cdRef.current === null) return null;
        var style = window.getComputedStyle(cdRef.current);
        //console.log(props.nkey + " : " + style.display);
        return (style.display === 'none')
    }

    if (props.time === null)
        return <Typography variant="body1">{props.nullText}</Typography>;

    if (props.code !== code) {
        setTimer({code: props.code, timer: props.time});
        setCode(props.code);
        setBaseTime(new Date());
    }


    if (timer.code === code && timer.timer >= -1) {
    	setTimeout(() => {
          let currentTime = new Date();
          if (timer.timer != null && timer.timer > 0.5) {
             let diff = (currentTime.getTime() - baseTime.getTime()) / 1000;
             if (diff >= 1) {
                let n = timer.timer - diff;
                setTimer({code: code, timer: n});
                setBaseTime(currentTime);
                if (props.sync) props.sync(n);
             }
          } else
              if (timer.timer !== null && timer.timer <= 0.5 && props.callback && !alerted && !isHidden()) {
                setAlerted(true);
                props.callback();                
              }
        if (timer.timer !== null && !isNaN(props.preAlert) && !preAlerted && !isHidden()
            && timer.timer <= props.preAlert + 0.5 && timer.timer >= props.preAlert - 0.5 && props.preAlertCallBack) {
                setPreAlerted(true);
                props.preAlertCallBack();
            }
        }, 1000);
    }

    const formatTime = (time) => {
        let st = "";
        if (time === null)
            return "—";

        if (time < 0.5) {
            st = "—";
        }
        else
        {
            let d = Math.floor(time / (60 * 60 * 24));
            let h = Math.floor((time - (d * 60 * 60 * 24)) / (60 * 60));
            let m = Math.floor((time - (d * 60 * 60 * 24) - (h * 60 * 60)) / 60);
            let s = Math.floor((time - (d * 60 * 60 * 24) - (h * 60 * 60) - (m * 60)));

            st += d > 0 ? d + " " + i18n.t("lDays") + " " : "";
            st += h > 9 ? h + ":" : (h > 0 ? "0" + h  + ":" : "");
            st += (m > 9 ? m : "0" + m ) + ":";
            st += s > 9 ? s : "0" + s;
        }

    	return st;
    }

    return (<Typography ref={cdRef} sx={props.sx}>
    			{formatTime(timer.timer)}
    		</Typography>);
}