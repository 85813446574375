import React from "react";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Chip, Grid } from '@mui/material';
import { NavigateNext as NextIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { setTitle } from './titleSlice.js';
import i18n from './i18n.js';
import theme from './../theme.js';

const routing = [
    {alias : "admin", text: "nAdmin"},
    {alias : "users", text: "nUsers"},
    {alias : "new", text: "nNew"},
    {alias : "simulations", text: "nSimulations"},
    {alias : "games", text: "nGames"},
    {alias : "accounts", text: "nAccounts"},
    {alias : "run", text: "nRun"},
    {alias : "home", text: "nHome"},
    {alias : "players", text: "nPlayers"},
    {alias : "manage", text: "nManage"},
    {alias : "mygames", text: "nMyGames"},
  ];

export default function Breadcrumbs(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const recordTitles = props.recordTitles;
  const [crumbs, setCrumbs] = useState([]); 

  useEffect(() => {
    let els = location.pathname.split("/");
    let crmbs = [];
    let path = "";
    let titleIdx = 0;
    let elFound = false;
    for (let i = 0; i < els.length; i++) {
       let el = els[i];
       if (el !== "") {
         let crumb = {};
         path = path + "/" + el;
         crumb.path = path;
         crumb.name = el;
         if (!isNaN(el)) {
            if (recordTitles && recordTitles !== null) {
              crumb.name =  recordTitles[titleIdx]; // + " [" + el + "]";
              crumb.title = recordTitles[titleIdx];
              crmbs.push(crumb);
              elFound = true;
              titleIdx += 1;
            }
         } else {
           for (let j = 0; j < routing.length; j++) {
              if (routing[j].alias === el)
              {
                  crumb.name = i18n.t(routing[j].text);
                  crumb.title = crumb.name;
                  if (elFound)
                    crumb.title += " " + crmbs[crmbs.length - 1].title;
                  crmbs.push(crumb);
                  break;
              }  
           }
         }
       }
    }

    dispatch(setTitle(crmbs[crmbs.length - 1].title));
    setCrumbs(crmbs);
  }, [location.pathname, dispatch, recordTitles]);

  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" key={"gridBC"}
          sx={
                {
                    paddingTop: theme.spacing(2),
                    paddingLeft: theme.spacing(2),
                    [theme.breakpoints.down('sm')]: {
                      display: "none"
                    }
                }
             }>
      {/* Link back to any previous steps of the breadcrumb. */}
      {crumbs.map(({name, path}, key) =>
         {
            const f = (key + 1 !== crumbs.length);
            const a = 
                  <Chip 
                    key={"f"+key} 
                    label={name} 
                    variant="outlined" 
                    style={f?{borderRadius: "6px"}:{border: "none"}} 
                    clickable={f}
                    onClick={f?() => {if (props.callback) props.callback(); navigate(path)} : null} />;
            const b = f?<NextIcon key={"ni_"+key}/>:null;

            return [a, b];
          }
      )}
    </Grid>
  );
};
