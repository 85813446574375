import React from 'react';
import Entity from './Entity.js';
import i18n from './i18n.js';

export default function Simulations(props) {
  const fields = [
     { field: 'id', headerName: i18n.t('fID'), width: 70, type: 'text', isReadOnly: true, display: "GU" },
     { field: 'name', headerName: i18n.t('fName'), width: 230, type: 'text', onChange: (() => {}), display: "GCU" },
     { field: 'description', headerName: i18n.t('fDescription'), width: 230, type: 'text',  display: "GCU" },
     { field: 'author', headerName: i18n.t('fAuthor'), width: 130, type: 'ref', isNullable: true,  display: "GCU", ref: {returnField: 'name', method: 'users'} },
     { field: 'script', headerName: i18n.t('fScript'), width: 230, type: 'code', display: "CU" },
  ];

  return (
    <Entity 
      title={i18n.t("tSimulations")}
      props={props} 
      fields={fields}
      method="simulations" 
      path="/admin/simulations"/>
  );
}
