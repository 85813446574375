import { colors } from '@mui/material';
import { createTheme } from '@mui/material';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#8D805B',
    },
    secondary: {
      main: '#888AB0',
      second: "#444662"
    },
    error: {
      main: colors.red.A400,
    },
    background: {
      default: '#fff',
    },
    hint: {
      main: '#A0A0A0',
      accented: 'red'
    }
  }
});

export default theme;
