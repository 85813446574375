import React from 'react';
import { styled } from '@mui/styles';
import { Box } from '@mui/material';

const FilledProgress = styled(Box)(({ theme, value }) => ({
  height: 20,
  borderRadius: 10,
  width: value + "%",
  backgroundColor: theme.palette.primary.main,
  display: 'inline-block',
  marginLeft: "-100%",
  zIndex: 1600
}));

const EmptyProgress = styled(Box)(({ theme, value }) => ({
  height: 20,
  borderRadius: 10,
  width: "100%",
  backgroundColor: theme.palette.grey[200],
  display: 'inline-block',
  zIndex: 1000
}));

export default function Progress(props) {
  let value = 0;
  if (props.max !== 0)
    value = Math.round(props.value * 100 / props.max);
  const trimmedValue = (value > 100 ? 100 : value < 0 ? 0 : value);

	return (
		<Box sx={{h: 6, border: "solid 1px #BDBDBD", borderRadius: "6px", mb: 3, p: 2}}>
			<Box sx={{mb: 1}}>{props.title + ": "}<b>{value + "%"}</b>{props.extraLineText}</Box>
      <EmptyProgress value={trimmedValue}/>
      <FilledProgress value={trimmedValue}/>
		</Box>
	);
}