import React from 'react';
import { Grid, IconButton, Box } from '@mui/material';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { styled } from '@mui/styles';
import theme from './../theme.js';

const Left = styled(Box)(
  ({ theme }) => ({
  	textOverflow: 'ellipsis',
  	overflow: "hidden",
  	display: "flex",
  	justifyContent: "flex-start",
  	margin: theme.spacing(1),
  	whiteSpace: "nowrap"
  }),
);

const Right = styled(Box)(
  ({ theme }) => ({
  	textOverflow: 'ellipsis',
  	display: "flex",
  	justifyContent: "flex-end",
  	backgroundColor: theme.palette.background.default,
  	margin: theme.spacing(1),
  	marginTop: 0,
  	paddingLeft: theme.spacing(1)
  }),
);

const CardN = styled(Box)(
  ({ theme }) => ({
  	padding: theme.spacing(2),
  	paddingTop: theme.spacing(1),
  	margin: theme.spacing(1),
  	marginLeft: theme.spacing(2),
  	height: theme.spacing(7),
  	border: "solid 1px #BDBDBD",
  	borderRadius: "6px"
  }),
);

const BoxRight = styled(Box)(
  ({ theme }) => ({
  	textOverflow: 'ellipsis',
  	display: "flex",
  	justifyContent: "flex-end",
  	margin: theme.spacing(1),
  	marginTop: theme.spacing(-4)
  }),
);

export default function LittleCard(props) {
	const sl = [12,12,12,12];
	var clickable = null;

	let stl = props.style;
	if (stl == null)
		stl = {};	

	if (props.onClick !== undefined && props.onClick !== null) {
		clickable =	(
									<IconButton onClick={props.onClick} size="small" edge="end" 
									 sx={{marginTop: "-5px", marginLeft: 1}}><ArrowForwardIosIcon/></IconButton>
								);
  	stl["cursor"] = "pointer";
	}

	var chld = null;
	if (props.children)
		chld = (<Right variant="body1">{props.children}</Right>);
	else
		chld =	(<Right variant="body1" sx={{color: props.isAccented ? theme.palette.error.main : 'primary'}}>
							{props.text}
					   </Right>);
	return (
		<CardN onClick={props.onClick} sx={props.long?{width: theme.spacing(126), ...stl}:{width: theme.spacing(40), ...stl}}>
			<Grid container justify="flex-start" alignItems="center">
				<Grid item xs={sl[0]} sm={sl[1]} md={sl[2]} lg={sl[3]}>
					<Left variant="body1">
						{props.label}
					</Left>
					<BoxRight display="flex">
					{ chld }
					{ clickable }
					</BoxRight>
				</Grid>
			</Grid>
		</CardN>
	);
}
