import React from 'react';
import { useNavigate } from "react-router-dom";
import { ListItem, ListItemText, Divider, Icon, ListItemIcon } from '@mui/material';

export default function MenuItems(props) {
	const navigate = useNavigate();

	let elements = props.menuItems.map(item => {
				let divider = "";
				if (item.divider && item.divider === true)
					divider = (<Divider/>);
				return 	(
						<div key={item.text+"_div"}>
						{divider}
						<ListItem button key={item.text}
								  onClick={(event) => { 
								  	  if (event.ctrlKey)
    									window.open(item.link, "_blank");
  									  else
  										navigate(item.link);
								  	if (props.callback)
								  		props.callback();  
								  }}>
							<ListItemIcon>
        						<Icon>{item.icon}</Icon>
      						</ListItemIcon>
      						<ListItemText primary={item.text} />
    					</ListItem>
    					</div>
    				);
    		});
	return elements;
}