import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, TextField, Link, Paper, Box, Grid, Typography, Autocomplete, Snackbar, Alert, Divider } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import { styled } from '@mui/styles';
import i18n from './i18n.js';
import y42 from './y42.js';
import theme from './../theme.js';
import bg0 from './../assets/img/bg0.jpg';


function Copyright() {
  let host = window.location.host;

  return (
    <Box sx={{bottom: 20, left: 20, position: "absolute", zIndex: 0, textShadow: "#FFFFFF 1px 0 3px, #FFFFFF -1px 0 3px, #FFFFFF 0 1px 3px, #FFFFFF 0-1px 3px"}}>
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.y42group.com/">
        Y42 Group
      </Link>{' '}
      {new Date().getFullYear() + " "}
      [{host}]
    </Typography>
    </Box>
  );
}

const GridImage = styled(Grid)(
  ({ theme }) => ({
    backgroundImage: 'url(' + bg0 + ')',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }),
);

export default function SignUp(props) {
  let navigate = useNavigate();
  const pageParams = useParams();
  if (pageParams.code === null || pageParams.code === undefined)
    pageParams.code = "";

  const [state , setState] = useState({
        code : (pageParams.code && pageParams.code !== "" && !pageParams.code.startsWith("wait") && !pageParams.code.startsWith("fin")) ? pageParams.code : "",
        userName : "",
        password : "",
        passwordRepeat : "",
        email: "",
        fullName: ""
  })
  const langs = i18n.getLanguages();
  const startLang = localStorage.getItem("lang") ? localStorage.getItem("lang") : window.navigator.language.substring(0, 2);
  const [lang, setLang] = useState(langs.find(item => item.key === startLang));
  const [alert, setAlert] = useState({open: false, severity: "info", message: ''});
  const [regMessage, setRegMessage] = useState("");

  useEffect(() => {
    if (pageParams.code.startsWith("wait"))
      setRegMessage(i18n.t("tCheckYourInbox"));
    else if (pageParams.code.startsWith("fin")) {
      y42.put("users/confirm", pageParams.code, null)
         .then((res) => {
            if (res._status !== y42.ok) {
              let msg = y42.getMessage(res._status, res.message);
              setRegMessage(i18n.t("tRegistrationFailed") + ": " + msg);
              setAlert({open: true, severity: "error", message: msg});
            }
            else {
              setRegMessage(i18n.t("tSuccesfullyEndedRegistration"));
            }
        })   
    }
  }, [pageParams]);

  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
          ...prevState,
          [id] : value
     }))
  }

  const handleSubmitClick = async (e) => {
      e.preventDefault();
      if (state.code === "" || state.password === "" || state.password !== state.passwordRepeat || 
          state.fullName === "" || state.email === "" || state.userName === "" ||
          !(/\S+@\S+\.\S+/.test(state.email)) || !(/\S\S\S-\S\S\S/.test(state.code))) {
        setAlert({open: true, severity: "error", message: i18n.t("mIncorrectUserRegData")});
        return;
      }  

      let usr = {
        name: state.userName,
        email: state.email,
        password: state.password,
        fullName: state.fullName
      };
      const res = await y42.post("users/new", state.code + "/" + btoa(y42.getSiteUrl()), JSON.stringify(usr));
      if (res._status !== y42.ok) {
          let msg = y42.getMessage(res._status, res.message);
          setRegMessage(i18n.t("tRegistrationFailed") + ": " + msg);
          setAlert({open: true, severity: "error", message: msg});
      } else
        window.location = "/signup/wait";
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert({open: false, severity: "info", message: ''});
  };

  let form = null;
      
  if (!pageParams.code.startsWith("fin") && !pageParams.code.startsWith("wait")) {
    form = (
        <Box sx={{width: "50vh", maxWidth: "100%"}}>
        <form noValidate autoComplete="off">
            <Autocomplete
              id="languageSelect" 
              key="languageSelect"
              name="languageSelect"
              fullWidth
              label={i18n.t("fSelectLanguage")} 
              disableClearable
              options={langs}
              value={lang}
              onChange={(ev, value) => {
                            i18n.config(value.key);
                            setLang(value);
                          }}
              getOptionLabel={
                            (option) => {
                              return (option && option.value)?option.value:""
                              }
                          }
              isOptionEqualToValue={
                (option, value) => option.key === value.key
              }
              renderInput={(params) => (
                              <TextField 
                                  {...params}
                                  margin="normal" 
                                  variant="outlined"/>
                            )}
            />
            <Typography variant="body1" sx={{pt: 4}}>
              {i18n.t("tWelcomeDataInput")}
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="code"
              label={i18n.t("fInvitationCode")}
              name="code"
              defaultValue={pageParams.code}
              onChange={handleChange}
              error={!(state.code === "" || /\S\S\S-\S\S\S/.test(state.code))}
              autoFocus={pageParams.code === ""}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={i18n.t("fEmail")}
              name="email"
              onChange={handleChange}
              error={!(state.email === "" || /\S+@\S+\.\S+/.test(state.email))}
              autoFocus={pageParams.code !== ""}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="fullName"
              label={i18n.t("fFullName")}
              name="fullName"
              onChange={handleChange}
            />
            <Divider sx={{pt: 4}}/>
            <Typography variant="body1" sx={{pt: 4}}>
              {i18n.t("tAccountDataInput")}
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="userName"
              label={i18n.t("fLoginName")}
              name="userName"
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={i18n.t("fPassword")}
              type="password"
              id="password"
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="passwordRepeat"
              label={i18n.t("fPasswordRepeat")}
              type="password"
              id="passwordRepeat"
              onChange={handleChange}
              error={state.password !== "" && state.passwordRepeat !== "" && state.password !== state.passwordRepeat}
            />
            {/*
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={i18n.t("fRememberMe")}
            />*/}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ margin: theme.spacing(3, 0, 2) }}
              onClick={handleSubmitClick}
            >
              {i18n.t("bSignUpConfirm")}
            </Button>            
          </form>
          </Box>
      );
  }

  return (
    <>
    <Copyright/>
    <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
        <Alert onClose={handleClose} severity={alert.severity}>
          {alert.message}
        </Alert>
    </Snackbar>

    <Grid container component="main" sx={{height: '100vh'}}>
      <CssBaseline />
  
      <GridImage item xs={false} sm={4} md={7} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} sx={{ zIndex: 1000 }}>
        <Paper sx={{padding: 4, paddingTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', height: "100%"}}>
          <Avatar sx={{margin: 1, backgroundColor: theme.palette.secondary.main}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {i18n.t("tSignUp")}
          </Typography>
          {form}
          {pageParams.code.startsWith("wait") || pageParams.code.startsWith("fin") ? (
                <Box mt={15}>
                  <Typography>{regMessage}</Typography>
                      <Button fullWidth variant="contained" color="primary" sx={{ margin: theme.spacing(3, 0, 2) }} onClick={() => navigate("/")}>
                        {i18n.t("bBackToLogin")}
                      </Button>
                </Box>)  : null
          }
        </Paper>
      </Grid>
    </Grid>
    </>
  );

}