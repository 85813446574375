import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, ReferenceLine  } from 'recharts';
import helper from './helper.js';

export default function MoveEffectDashboard(props) {
	if (!props.gameId || props.gameId < 0) return;
	const res = props.moveEffect;

	if (!res) return;
	if (!res.dashboards) return;

  let me = [];

  res.dashboards.map((db, idx2) => {
    let min = 10000000, max = -10000000;
    db.data.map((el, idx) => {
      for (let i = 0; i < el.values.length; i++) {
        if (el.values[i] > max)
          max = el.values[i];
        if (el.values[i] < min)
          min = el.values[i];
      }
      return null;
    });
    let ticks = [];
    for (let j = Math.floor(min / 10) * 10; j <= Math.ceil(max / 10) * 10; j+=10) {
      ticks.push(j);
    }

    me.push(
      <Box sx={{width: '100%'}} key={"dbt_"+idx2}>
        <Typography variant="button">{db.title}</Typography>
      </Box>
    );
    db.data.map((el, idx) => {
      let cd = [];
      for (let i = 0; i < el.values.length; i++) {
          cd.push({
            key: (i + 1),
            value: el.values[i]
          })
      }
      me.push(
        <Grid sx={{ml: -1, pt: 2}} key={"db_"+idx2 + "_"+idx} >
          <Box sx={{fontSize: "10pt", color: helper.getColorByIndex2(idx2, idx)}}>{el.key}</Box>
          <BarChart data={cd} width={200} height={120} margin={{top: 5, right: 5, bottom: 5, left: -30 }}>
                <XAxis dataKey="key" name={db.xTitle} axisLine={false} tickLine={false} tick={{fontSize: '8pt', color: "#f0f0f0"}}/>
                <YAxis type="number" domain={[min, max]} ticks={ticks} axisLine={{stroke: "#f0f0f0"}} tickLine={{stroke: "#f0f0f0"}} tick={{fontSize: '8pt', color: "#f0f0f0"}}/>
                <ReferenceLine y={0} stroke="#f0f0f0"/>
                <Bar dataKey="value" fill={helper.getColorByIndex2(idx2, idx)}/>
          </BarChart>
          <Box sx={{height: "10px"}}/>
        </Grid>
      );       
      return null; 
    });
    return null
  });

  return <React.Fragment key={props.move}>{me}</React.Fragment>;
}