import React from 'react';
import { Routes, Route } from "react-router-dom";
import BasicPage from './BasicPage.js';
import MyGames from './MyGames.js';
import RunGamePlayer from './RunGamePlayer.js';      
import Dashboard from './Dashboard.js';
import i18n from './i18n.js';

export default function Home() {
  const menuItems = [
    {icon: "home", text: i18n.t("mDashboard"), link: '/home'},
    {icon: "sports_esports", text: i18n.t("mMyGames"), link: '/home/mygames'},
  ];

  let inner = ( 
    <Routes>
      <Route key="home" exact path="/*" element={<Dashboard/>}/>
      <Route key="mygames" exact path="mygames" element={<MyGames/>}/>
      <Route key="mygame" exact path="mygames/:id" element={<RunGamePlayer/>}/>
    </Routes>
  );        
  
  return <BasicPage title={i18n.t('tHome')} menuItems={menuItems} routesComponents={inner}/>;
}