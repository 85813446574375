import React, { useState, useEffect } from 'react';
import Loading from './Loading.js';
import Chart from './Chart.js';
import i18n from './i18n.js';
import y42 from './y42.js';

export default function BehaviorAnalysis(props) {

  const [state, setState] = useState({isLoaded : false, isLoading : false});
  const [players, setPlayers] = useState(null);
  const [stats, setStats] = useState({});

  const titles = ["lReadSituation", "lReadResults", "lChangeMove", "lSendMove", "lSwitchTabs", "lShowMoveEffects"];
  const keys = ["readSituation", "readResults", "changeMove", "sendMove", "switchTabs", "showMoveEffects"];
  const actions = ["Read situation", "Read results", "Change move", "Send move", "Switch tab", "Show move effects"];

  let avg = {};
  let min = {};
  let max = {};
  for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      avg[k] = 0;
      min[k] = 0;
      max[k] = 1E6;
  }

  useEffect(() => {
    async function fetch() {
      setState({isLoaded: false, isLoading: true});

      let players2 = [];
      let qrs = [];
      for (let p = 0; p < props.gs.game.playersNumber; p++) {
        let pid = props.gs.playerStatuses[p].player.id;
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i]; 
            if (k === "readSituation" || k === "readResults")
              qrs.push({
                  aggregate: "sum",
                  subject: "duration",
                  action: actions[i],
                  playerId: pid
              });
            else
              qrs.push({
                  aggregate: "count",
                  subject: "data",
                  action: actions[i],
                  playerId: pid
              });
        }
      }

      let r = await y42.getActionsByQueries(qrs);
      let ri = 0;
      for (let p = 0; p < props.gs.game.playersNumber; p++) {
        let pid = props.gs.playerStatuses[p].player.id;
        let plr = {
                        id : pid, 
                        name : props.gs.playerStatuses[p].player.name,
                        x : props.gs.playerStatuses[p].player.name,
                        label: {}
                  }
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i]; 
            plr[k] = r[ri].result;
            if (plr[k] > max[k]) max[k] = plr[k];
            if (plr[k] < min[k]) min[k] = plr[k];
            avg[k] += plr[k];
            ri++;
        }
        players2.push(plr);
      }

      for (let i = 0; i < keys.length; i++) {
        const k = keys[i];
        avg[k] = Math.round(avg[k] / props.gs.game.playersNumber);
      }

      for (let p = 0; p < props.gs.game.playersNumber; p++) {
          for (let i = 0; i < keys.length; i++) {
              const k = keys[i]; 
              const a = Math.round((players2[p][k] / avg[k] - 1) * 100);
              const sa = a > 0 ? a + i18n.t("lAboveAverage") : a < 0 ? (-a) + i18n.t("lBelowAverage") : i18n.t("lExactlyAverage");
              players2[p]["a" + k] = a > 20 ? 3 : a < -20 ? 1 : 2;
              players2[p].label["tt" + k] = players2[p][k] + "\n" + sa;
          } 
      }
      for (let p = 0; p < props.gs.game.playersNumber; p++) {
          players2[p].communications = players2[p]["aswitchTabs"] + players2[p]["achangeMove"] + players2[p]["asendMove"];
          players2[p].analysis = players2[p]["areadSituation"] + players2[p]["areadResults"];
          players2[p].experiments = players2[p]["achangeMove"] / 2 + players2[p]["asendMove"] / 2 + players2[p]["ashowMoveEffects"];
          players2[p].label.ttGrades = "0\n" + //first element in tooltip line will be skipped
              (players2[p].communications > 7 ? i18n.t("lCommHi") : players2[p].communications < 5 ? i18n.t("lCommLo") : i18n.t("lCommAv")) + "\n" +
              (players2[p].analysis > 5 ? i18n.t("lAnalHi") : players2[p].analysis < 3 ? i18n.t("lAnalLo") : i18n.t("lAnalAv")) + "\n" +
              (players2[p].experiments > 5 ? i18n.t("lExprHi") : players2[p].experiments < 3 ? i18n.t("lExprLo") : i18n.t("lExprAv"));

      }
      setPlayers(players2);
      setState({isLoaded: true, isLoading: false});
      setStats({avg, min, max});
    }

    if (!props.gs || props.gs === null) 
      return;

    if (state.isLoading || state.isLoaded)
      return;

    fetch();
  });

  if (!props.gs || props.gs === null) 
    return;
  
  if (!state.isLoaded)
    return (<Loading/>);

  let chrts = [];
  for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      chrts.push(      
          <Chart key={"chart_" + k}
                 type="bar"
                 xname={i18n.t("lTeams")}
                 yname={i18n.t(titles[i])} 
                 name={i18n.t(titles[i])}
                 colorScheme={1}
                 yFields={k}
                 tooltipField={"tt"+k}
                 data={players}
                 refLine={stats.avg[k]}
                 refTitle={i18n.t("lAverage")}/>
        );
  }

  chrts.push(      
          <Chart key={"chart_grades"}
                 type="bar"
                 xname={i18n.t("lTeams")}
                 yname={i18n.t("lGrades")} 
                 name={i18n.t("lGrades")}
                 colorScheme={4}
                 yFields={["communications", "analysis", "experiments"]}
                 yTitles={[i18n.t("lCommAx"), i18n.t("lAnalAx"), i18n.t("lExprAx")]}
                 tooltipField={"ttGrades"}
                 data={players}/>    
  );

  return (
    <>
      {chrts}
    </>
  );
}