import React from 'react';
import { useParams } from "react-router-dom";
import Entity from './Entity.js';
import i18n from './i18n.js';

export default function Players(props) {
  const pageParams = useParams();
  const fields = [
     { field: 'id', headerName: i18n.t('fID'), width: 70, type: 'text', isReadOnly: true },
     { field: 'name', headerName: i18n.t('fName'), width: 230, type: 'text', onChange: (() => {}) },
     { field: 'game', headerName: i18n.t('fGame'), width: 130, type: 'ref', isNullable: false, ref: {returnField: 'name', method: 'games'} },
     { field: 'robot', headerName: i18n.t('fRobot'), width: 130, type: 'boolean', isNullable: false },
     { field: 'users', headerName: i18n.t('fUsers'), width: 330, type: 'ref', isNullable: true, isMultiple: true, ref: {returnField: 'name', method: 'users'} },
     { field: 'profile', headerName : i18n.t('fPlayerProfile'), width: 280, type: 'list', isNullable: true, list: {method: 'games/' + pageParams.fid + '/playerProfiles'}, display: "CUG"},
     { field: 'script', headerName: i18n.t('fScriptRobot'), width: 230, type: 'code', display: "CU" }
  ];

  return (
    <>
      <Entity
        title={i18n.t("tPlayers")}
        props={props} 
        fields={fields}
        method="players"
        gridMethod={"game/" + pageParams.fid + "/players"}
        filteredByField="game"
        />
    </>
  );
}
