import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, AppBar, Toolbar,IconButton, Typography, TextField } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import i18n from './i18n.js';

const CodeEditor = props => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
      setValue((props.value&&props.value !== null)?props.value:"");
  }, [props.value])

  const onChange = (ev) => {
    const newValue = ev.target.value;
    props.onChange(props.name, newValue);
    setValue(newValue);
    //return newValue;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

/*
  const handleClickCopy = () => {
    navigator.clipboard.writeText(value);
  };
*/
  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    if (props.saveCallback)
       props.saveCallback();
    if (props.closeOnSave)
      handleClose();
  };

  //console.log(props);

  return (
    <React.Fragment key={props.id + "_div"}>
      <Box>
        <TextField 
            key={"ae_" + props.id} 
            multiline 
            rows={4}
            value={value}
            label={props.label}
            InputProps={{
             readOnly: true,
             style: { 
                fontSize: '1em',
                fontFamily: 'Monospace',
                width: props.width
              }
            }}
            margin="dense"
            variant="outlined"
            fullWidth
            disabled={props.disabled}
        />
      </Box>
      <Box>
        <Button onClick={handleClickOpen} size="small">{i18n.t("bEditCode")}</Button>
      </Box>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{props.label}</Typography>
            <Button color="inherit" onClick={save} sx={{ml: 2}}>{props.saveButtonLabel}</Button>
          </Toolbar>
        </AppBar>
        <Toolbar/>
        <TextField 
            key={"ae2_" + props.id} 
            multiline
            maxRows={Infinity}
            value={value}
            onChange={onChange}
            InputProps={{
             style: { 
                fontSize: '1em',
                fontFamily: 'Monospace',
                width: props.width
              }
            }}
            margin="dense"
            variant="standard"
            fullWidth={props.width?false:true}
            disabled={props.disabled}
        />
          {/*mode={props.language}
            language="javascript"
          theme="github"
          onChange={onChange}
          name={props.name}
          id={props.id}
          key={"ae2_" + props.id}
          editorProps={{ $blockScrolling: true }}
          width="100%"
          showGutter={props.showLineNumbers}
          height="100%"
          fontSize={14}
          value={value}
          readOnly={props.disabled}

            style={{ 
                fontSize: '1em',
                fontFamily: 'Monospace',
                width: props.width,
                overflow: 'auto',
                backgroundColor: '#ffffff'
              }}



          */}
      </Dialog>
    </React.Fragment>
  );
};

export default CodeEditor;