import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, Box, Link } from '@mui/material';
import { ContentCopy as CopyIcon } from '@mui/icons-material';
import { QRCodeCanvas as QRCode } from 'qrcode.react';
import i18n from './i18n.js';
import y42 from './y42.js';

const ShowInvCode = props => {
  const user = y42.getUser();

  const handleClose = () => {
    props.onClose();
  };

  const link = y42.getSiteUrl() + "/signup/" + user.account.code;

  return (
  	<>
      <Dialog open={props.open} onClose={handleClose} width="100ch">
		     <DialogTitle id="title">{i18n.t("lInvitationCode")}</DialogTitle>
	 	     <DialogContent>
	 	     		<Box sx={{mt: 1, justifyContent : "center", display: "flex"}}>
	     	 			{user.account.code}
		          <IconButton
		            color="inherit"
		            onClick={() => {navigator.clipboard.writeText(user.account.code)}}
		            edge="start"
		            sx={{
		              ml: 1,	
		              mt: -1      
		            }}
		          >
	            	<CopyIcon />
	  	        </IconButton>
	  	      </Box>
	  	      <Box sx={{pt: 4, justifyContent : "center", display: "flex"}}>
	  	        <Typography>
		  	        { i18n.t("tSignUpLinkDesc")}
	  	        </Typography>
	  	      </Box>
	  	      <Box sx={{justifyContent : "center", display: "flex"}}>
		  	        <Link href={link}>
		  	      	 {link}	  	        	
		  	        </Link>	  	      	
	  	          <IconButton
		              color="inherit"
		              onClick={() => {navigator.clipboard.writeText(link)}}
		              edge="start"
		              sx={{
		                ml: 1,	
		                mt: -1      
		              }}
		            >
	            		<CopyIcon />
	  	        	</IconButton>
	  	      </Box>
	  	      <Box sx={{pt: 4, justifyContent : "center", display: "flex"}}>
	  	      	<QRCode value={link}/>
	  	      </Box>
	  	      <Box sx={{pt: 4, justifyContent : "center", display: "flex"}}>
	  	        <Typography>
	  	        { i18n.t("tHowToUseInvCode")}  	        	
	  	        </Typography>	  	      	
	  	      </Box>
      	 </DialogContent>
     	 <DialogActions>
         	<Button onClick={handleClose}>{i18n.t("bConfirm")}</Button>
         </DialogActions>
      </Dialog>
    </>
  );
};

export default ShowInvCode;