const en = {
	__language__: "English",

	fID: "ID",
	fName: "Name", 
	fNewPassword: "New Password",
	fRepeatNewPassword: "Repeat New Password",
	fSimulation: "Simulation",
	fInitiator: "Initiator",
	fNumberOfPlayers: "Number of players",
	fNumberOfSteps: "Number of rounds",
	fMinutesForOneStep: "Minutes for a round",
	fGame: "Game",
	fRobot: "Is Robot?",
	fUsers: "Users",
	fPlayerProfile: "Player's Profile",
	fScriptRobot: "Script for Robot",
	fLoginName: "Login Name",
	fPassword: "Password",
	fRememberMe: "Remember Me",
	fDescription: "Description",
	fAuthor: "Author",
	fScript: "Script",
	fFullName: "Full Name",
	fEmail: "E-mail",
	fAccount: "Account",
	fRole: "Role",
	fSelectLanguage: "Select language",
	fMoveModeStrict: "Strict Move Mode",
	fStartTime: "Start Time",
	fVariant: "Variant",
	fInvitationCode : "Invitation Code",
	fPasswordRepeat: "Repeat password",
	fIsActive: "Show on dashboard",
	fTerminationDate: "Account termination date",
	fMaxPlayers: "Maximum players limit",
	fLanguage: "Language",
	
	mDashboard: "Dashboard",
	mAccounts: "Accounts",
	mUsers: "Users",
	mSimulations: "Simulations",
	mGames: "Games",
	mAsUser: "As User",
	mMyGames: "My Games",
	mChangePassword: "Change Password",
	mLogout: "Logout",
	mChangeLanguage: "Change Language",
	mPreAlert: "One minute left in round. Don't forget to post your move",
	mSessionExpired: "Session is expired. Please enter user name and password again",
	mIncorrectUserRegData: "Incorrect data for user registration. Please fill the fields correctly and try again",
	mGameCloned: "Game cloned successfully. Navigate to it: ",
	mAccountActive: "Active",
	mAccountInactive: "Inactive",

	tAdministrativePanel: "Administrative Panel",
	tChangePassword: "Change Password",
	tDeleteRecord: "Delete Record",
	tRunningGames: "Currently Running Games",
	tHome: "Home",
	tManagementPanel: "Management Panel",
	tAccounts: "Accounts",
	tGames: "Games",
	tMyGames: "My Games",
	tCharts: "Charts",
	tParticipatingInGame: "Participating in the Game",
	tRunningGame: "Running the Game",
	tSignIn: "Sign In",
	tSimulations: "Simulations",
	tUsers: "Users",
	tReport: "Report",
	tBasicDashboard: "Dashboard",
	tOtherGames: "Other games",
	tPlayers: "Players",
	tPlayer: "Player",
	tHowToUseInvCode: "Send this code your players for registration",
	tSignUpComment: " if you don't have a user account",
	tSignUp: "Sign Up",
	tSignUpLinkDesc: "Share the hyperlink or QR code to Sign Up page",
	tInvitationCode: "Invitation Code",
	tSuccesfullyEndedRegistration: "New user registration is succsfully done! Now, you may sign in.",
	tCheckYourInbox: "Thank you for signing up! Check your email inbox and follow the link in the message.",
	tRegistrationFailed: "Registration failed",
	tWelcomeDataInput: "Welcome! Please let us know about you",
	tAccountDataInput: "Enter your username and password",
	tAccountInfo: "Account",
	tDeficite: "Debt growth",
	tProficite: "Debt decrease",

	bChangePassword: "Save New Password",
	bCancel: "Cancel",
	bEditCode: "Edit",
	bCodeSave: "Save",
	bNew: "New",
	bDeleteRecord: "Delete",
	bPlayers: "Players",
	bRunGame: "Game Master Panel",
	bRunPlayerGame: "Play Game",
	bNextStep: "Next Round",
	bDefinePlayers: "Define Players",
	bRestartGame: "Restart Game",
	bStopGame: "Stop Game",
	bStartGame: "Start Game",
	bUpdateToServer: "Update to Server",
	bExportToFile: "Export to File",
	bLoadFromFile: "Load from File",
	bClearSituations: "Clear Situations",
	bClose: "Close",
	bPrint: "Print",
	bSendMove: "Post Move",
	bSendMoveAgain: "Repost Move",
	bSignIn: "Sign In",
	bSave: "Save",
	bDelete: "Delete",
	bToList: "To List",
	bRefresh: "Refresh",
	bStepBack: "Step Back",
	bClearPassword: "Reset Password",
	bPauseGame: "Pause",
	bResumeGame: "Resume",
	bCopyPrevMove: "Copy Last Move",
	bResetMove: "Reset Move to Default",
	bFilter: "Filter",
	bGenerateCode: "Get New Code",
	bClearCode: "Clear Code",
	bConfirm: "OK",
	bSignUp: "Sign up",
	bSignUpConfirm: "Sign Up",
	bRemindPassword: "Forgot password?",
	bBackToLogin: "Go to sign in page",
	bCloneGame: "Clone Game",

	lDays: "days",
	lOpen: "Open",
	lGames: "Games",
	lGame: "Game",
	lNowRunning: "Now Running",
	lTotalPlayers: "Total Players",
	lLoading: "Loading ...",
	lNoRunningGames: "No running games",
	lAreYouSureToDelete: "Are you sure to delete the record?",
	lSavedSuccessfully: "Saved successfully",
	lDeletedSuccessfully: "Deleted successfully",
	lWhileGetting: "while getting",
	lStep: "Round",
	lNextStepSuccessful: "Next round ran successfully",
	lStepBackSuccessful: "Returned back for one round successfully",
	lSituationSentSuccessfully: "Situation sent successfully",
	lGameStoppedSuccessfully: "Game stopped successfully",
	lGameStartedSuccessfully: "Game started successfully",
	lSituationsSavedSuccessfully : "Situations saved successfully",
	lSituationsLoadedSuccessfully: "Situations loaded successfully. Don't forget to update to server",
	lSituationsClearedSuccessfully: "Situations cleared successfully. Don't forget to update to server",
	lGameStatus: "Game Status",
	lNoPlayers: "No players",
	lScore: "Score",
	lRating: "Rating",
	lGameEnded: "Ended",
	lGameRunning: "Running",
	lGameNotStarted: "Not started",
	lCurrentStep: "Current round",
	lAllStepsDone: "All done",
	lStepTimeLeft: "Time left in round",
	lPlayerDone: "Move Done",
	lPlayerThinking: "Thinking",
	lSituation: "Situation",
	lResults: "Results",
	lSelectedPlayer: "Selected Player",
	lSelectedStep: "Selected Round",
	lDescription: "Description",
	lMovePostedSuccessfully : "Move posted successfully",
	lMove: "Move",
	lSituationForStep: "Situation for the Round",
	lMoveForm: "Move in the Round",
	lReportFor: "Report for",
	lReportForRound: "Report for round",
	lPasswordCleared: "Password reset successfully",
	lNewRecord: "[New Record]",
	lMinimumLimit: "not less than",
	lMaximumLimit: "not more than", 
	lMinimumLimitFrom : "in the range from",
	lMinimumLimitTo : "to",
	lGamePausedSuccessfully: "Game paused successfully", 
	lGameResumedSuccessfully: "Game resumed successfully",
	lGamePaused: "Paused",
	lProfile: "profile",
	lRemainingAmount: "Amount to distribute",
	lGeneral: "Common",
	lExactly: "exactly",
	lSituationHint: "Put hidden situation insights in sections delimited by ###",
	lInsight: "Attention! Insight!",
	lNoOtherGames: "No games",
	lGameVariant: "Game variant",
	lProfileLabel: "Profile",
	lFilter: "Filter",
	lAdmin: "Owner",
	lAccManager: "Administrator",
	lInvitationCode: "Invitation Code for ",
	lTotal: "Total",
	lStats: "UI Stats",
	lReadSituation: "Studying situations, sec.", 
	lReadResults: "Diving into results, sec.", 
	lChangeMove: "Number of move parameters changes", 
	lSendMove: "Number of move posts", 
	lSwitchTabs: "Number of tab switching", 
	lShowMoveEffects: "Number of showing move influence",
	lAboveAverage: "% above average",
	lBelowAverage: "% below average",
	lExactlyAverage: "average",
	lAverage: "Average",
	lTeams: "Players",
	lCommHi: "High communication",
	lCommLo: "Low communication",
	lCommAv: "Moderate communication",
	lAnalHi: "Deep analysis",
	lAnalLo: "Superficial analysis",
	lAnalAv: "Moderate analysis",
	lExprHi: "Active experimenting",
	lExprLo: "Weak experimenting",
	lExprAv: "Moderate experimenting",
	lGrades: "Team profile",
	lCommAx: "Communication",
	lAnalAx: "Analysis",
	lExprAx: "Expetimenting",
	lAccountActive: "Account",
	lTerminationDate: "Termination date",
	lMaxPlayers: "Max players in game",

	nAdmin : "Admin panel",
	nUsers : "Users",
	nNew : "New entry",
	nSimulations: "Simulations",
	nGames: "Games",
	nAccounts: "Accounts",
	nRun: "Run game",
	nHome: "Home",
	nPlayers: "Players",
	nManage: "Management",
	nMyGames: "My games",

	errNoConnection: "No connection",
	errAccessDenied: "Access denied. Verify user name and password, and try again",
	errOperationUnavailable: "Operation unavailable",
	errOperationNotFound: "Operation not found",
	errInternalServerError: "Internal server error",
	errUseLatingLettersInPassword:	"Please use non-empty password with latin letters",
	errError: "Error",
	errDoesntMatchFormat: "doesn't match format",
	errExceedsRange: "exceeds range of",
	errIsNegative: "must be greater or equal to 0",
	errCannotLoadSituationsWhenGameStarted : "Cannot load situation when game is started",
	errIncorrectFileFormat: "Incorrect file format",
	errCannotClearSituationsWhenGameStarted : "Cannot clear situation when game is started",
	errCannotBeGreaterThan: "cannot be greater than",
	errCannotBeLessThan: "cannot be less than",
	errThereAreErrorsOnSave: "Cannot save, there are errors in data",
	errTogether: "in total",

	msgGameStatusChanged: "ATTENTION! The game status has been changed!"
};

export default en;