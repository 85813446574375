import React, { useState } from "react";
import { Snackbar, Alert } from '@mui/material';

export default function AlertMessage(props) {
	const [open, setOpen] = useState(props.open);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

	return (
		<Snackbar open={open} 
				autoHideDuration={6000} 
				onClose={handleClose} 
				anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
               }}
        sx={props.sx}>
        <Alert onClose={handleClose} severity={props.severity}>
          {props.message}
        </Alert>
    </Snackbar>
	)
}