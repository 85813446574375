const colors = ["#ef6101", "#fdac07", "#47802b", "#045658", "#03161d", "#c80003", "#ff955f", "#a6c64c", "#405d3a", "#86c6be"];
const colors2 = [
			["#8d805b", "#bfab73", "#ccc762", "#acbd57", "#aacf70"],
			["#D8D8F6", "#B18FCF", "#978897", "#494850", "#2C2C34"],
			["#E3170A", "#E16036", "#D6A99A", "#D6CBC1", "#CDD6D0"],
			["#1E352F", "#335145", "#828C51", "#A6C36F", "#BEEF9E"],
			["#068D9D", "#53599A", "#6D9DC5", "#80DED9", "#AEECEF"]				
	];

class helper {

	getColorByIndex = (index) => {
		return colors[index % 10];
	}

	getColorByIndex2 = (scheme, index) => {
		return colors2[scheme % 5][index % 5];
	}

	getColorSchemeArray2 = (scheme) => {
		return colors2[scheme % 5];
	}
}

export default new helper();