import en from './langs/en.js';
import ru from './langs/ru.js';

class i18n {
	language = null;
	langs = {
		ru: ru,
		en: en
	}

/*
	config = async (lang) => {
		let ml = await import('./langs/' + lang + '.js');
		this.language = ml.default;
		localStorage.setItem('lang', lang);
		return this.language;
	}
*/

	config = (lang) => {
		let nlang = lang;

		const keys = Object.keys(this.langs);
		const foundLang = keys.find(item => item === nlang);

		if (nlang === null || nlang === undefined || nlang === "null" || foundLang  === undefined)
			nlang = window.navigator.language.substring(0, 2);
		localStorage.setItem('lang', nlang);
		this.language = this.langs[nlang];
		return this.language;
	}

	t = (code) => {
		let lng = this.language;

		if (lng === null || lng === undefined) {
			let lang = localStorage.getItem('lang');
			lng = this.config(lang);
			if (lng === undefined || lng === null)
				return "!!" + code;
		}
		
		let l = lng[code];
		if (l === undefined || l == null)
			return "!!" + code;

		return l;
	}

	getLanguages = () => {
		let keys = Object.keys(this.langs);
		let l = [];

		for (let i = 0; i < keys.length; i++) {
			l.push({key: keys[i], value: this.langs[keys[i]]["__language__"]});
		} 

		return l;
	}
}

export default new i18n();