import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/styles';
import { Grid, Typography, Box, Button } from '@mui/material';
import AlertMessage from './AlertMessage.js';
import LittleCard from './LittleCard.js';
import Loading from './Loading.js';
import i18n from './i18n.js';
import y42 from './y42.js';
import theme from './../theme.js';
import ShowInvCode from './ShowInvCode.js';

const Title = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(2), 
    marginLeft: theme.spacing(3), 
    marginBottom: theme.spacing(1)
  }),
);

export default function Dashboard(props) {
	const navigate = useNavigate();
  const user = y42.getUser();

	const [state, setState] = useState({isLoaded : false, isLoading : false}); 
	const [dashboard, setDashboard] = useState({});
  const [code, setCode] = useState(user !== null ? user.account.code : "");
  const [showInvCode, setShowInvCode] = useState(false);
	const [alert, setAlert] = useState({open: false, severity: "info", message: ''});
  
	useEffect(() => {
		async function fetch() {
      setState({isLoaded : false, isLoading : true});

      const res = await y42.get("dashboard", null);
      if (res._status !== y42.ok) {
        setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message) + " while getting dashboard"});
      } else {
        let u = y42.getUser();
        let d1 = new Date(u.account.termDate).getTime();
        let d2 = new Date().getTime();
        res.accActive = (d1 >= d2) || u.account.termDate === null;
        res.termDate = u.account.termDate === null ? "—" : u.account.termDate.toString().substring(0,10);
        res.maxPlayers = u.account.maxPlayers;
      	setDashboard(res);
 	    	setState({isLoaded : true, isLoading : false});
      }
		}

		if (state.isLoaded || state.isLoading)
			return;

		fetch();
	});

  async function genCode(b) {
    let f = "newCode";
    if (!b)
      f = "clearCode";

    const res = await y42.get("accounts/"+user.account.id+"/"+f, null);
    if (res._status !== y42.ok) {
        setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
      } else {
        user.account.code = res.code;
        y42.setUser(user);
        setCode(res.code);
      }
  }

  const generateCode = () => {
    genCode(true);
  }

  const clearCode = () => {
    genCode(false);
  }

  if (!user || !user.role)
    return null;

  if (!state.isLoaded)
  	return <Loading />;

  var cards = [];
  var cards2 = [];
  var plNumber = null;
  var invCode = null;
  var pathRun1 = "./games";
  var pathRun2 = "/run";
  var clickGames = null;

  if (user.role.name === "ROLE_ADMIN" || user.role.name === "ROLE_ACCMANAGER") {
      plNumber = <LittleCard label={i18n.t("lTotalPlayers")} text={dashboard.playersNumber} />;
      invCode = (
        <>
        <Box sx={{pt: 4}}>
          <Title variant="button">{i18n.t("tInvitationCode")}</Title>
        </Box>
        <Grid container justify="flex-start" alignItems="center" sx={{marginLeft: theme.spacing(1)}}>
          <LittleCard
             label={i18n.t("lInvitationCode") + user.account.name} 
             text={code} 
             long={window.innerWidth>theme.breakpoints.values.sm}
             onClick={() => {setShowInvCode(true);}}/>
        </Grid>
        <Box>
          <Button onClick={generateCode} sx={{ml: 3}}>{i18n.t("bGenerateCode")}</Button> 
          <Button onClick={clearCode} sx={{ml: 1}}>{i18n.t("bClearCode")}</Button> 
        </Box>
        </>
      );
  }

  if (user.role.name === "ROLE_ADMIN") {
    clickGames = () => {navigate("./games")};
  }
  if (user.role.name === "ROLE_ACCMANAGER") {
    clickGames = () => {navigate("./games")};
  }

  if (user.role.name === "ROLE_USER") {
      pathRun1 = "./mygames";
      pathRun2 = "";
  }

  dashboard.gamesRunningNumber = 0;
	for (let i = 0; i < dashboard.gamesRunning.length; i++) {
    let gs = dashboard.gamesRunning[i];
    gs.isGameStarted = gs.startTime != null;
    gs.isGameEnded = gs.currentStep == null ? true : (gs.currentStep + 1 > gs.stepsNumber);
    gs.isGamePaused = gs.paused;

    let st = gs.isGameStarted?(gs.isGameEnded?i18n.t("lGameEnded"):(gs.isGamePaused?i18n.t("lGamePaused"):i18n.t("lGameRunning"))):i18n.t("lGameNotStarted");
    let el = (<LittleCard 
                  key={"lc_"+i}
                  label={gs.name} 
                  text={st} 
                  long={window.innerWidth>theme.breakpoints.values.sm}
                  onClick={() => { navigate(pathRun1 + "/" + dashboard.gamesRunning[i].id + pathRun2)}}/>);
    if (st === i18n.t("lGameEnded") || st === i18n.t("lGameNotStarted"))
      cards2.push(el);
    else {
      cards.push(el);
      dashboard.gamesRunningNumber += 1;
    }
	};

  if (cards.length === 0)
    cards = <Typography variant="body2" sx={{paddingLeft: theme.spacing(2)}}>{i18n.t("lNoRunningGames")}</Typography>;
  if (cards2.length === 0)
    cards2 = <Typography variant="body2" sx={{paddingLeft: theme.spacing(2)}}>{i18n.t("lNoOtherGames")}</Typography>;

  return (
  	<>
      <AlertMessage open={alert.open} severity={alert.severity} message={alert.message}/>
      <Title variant="button">
          {i18n.t("tBasicDashboard")}
      </Title>
      <Grid container justify="flex-start" alignItems="center" sx={{marginLeft: theme.spacing(1)}}>
        <LittleCard label={i18n.t("lGames")} text={dashboard.gamesNumber} onClick={clickGames} />
        <LittleCard label={i18n.t("lNowRunning")} text={dashboard.gamesRunningNumber} />
        { plNumber }
      </Grid>
      { user.role.name === "ROLE_ACCMANAGER" ? ( 
        <Box sx={{pt: 4}}>
          <Title variant="button">
              {i18n.t("tAccountInfo")}
          </Title>
          <Grid container justify="flex-start" alignItems="center" sx={{marginLeft: theme.spacing(1)}}>
            <LittleCard label={i18n.t("lAccountActive")} text={dashboard.accActive ? i18n.t("mAccountActive") : i18n.t("mAccountInactive")}/>
            <LittleCard label={i18n.t("lTerminationDate")} text={dashboard.termDate}/>
            <LittleCard label={i18n.t("lMaxPlayers")} text={dashboard.maxPlayers}/>
          </Grid>
        </Box>) : null
      }
      { invCode }
      <Box sx={{pt: 4}}>
        <Title variant="button">{i18n.t("tRunningGames")}</Title>
      </Box>
      <Grid container justify="flex-start" alignItems="center" sx={{marginLeft: theme.spacing(1)}}>
        { cards }
      </Grid>
      <Box sx={{pt: 4}}>
        <Title variant="button">{i18n.t("tOtherGames")}</Title>
      </Box>
      <Grid container justify="flex-start" alignItems="center" sx={{marginLeft: theme.spacing(1)}}>
        { cards2 }
      </Grid>
      <ShowInvCode open={showInvCode} onClose={() => {setShowInvCode(false)}}/>
      </>
  	);
}