import React from 'react';
import { ResponsiveContainer, Legend, ScatterChart, Scatter, LineChart, Line, BarChart, Bar, RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, LabelList, Cell, ReferenceLine } from 'recharts';
import { Grid, Box } from '@mui/material';
import { Chart as GChart } from "react-google-charts";
import { styled } from '@mui/styles';
import helper from './helper.js';
import i18n from './i18n.js';

const Sign = styled(Box)(
  ({ theme }) => ({
    marginRight: "5px",
    marginLeft: 0,
    marginTop: "3px",
    marginBottom: 0,
    borderRadius: "3px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    flexShrink: 0
  }),
);


const fmt = (s) => {
  if (Math.round(s) !== s * 1) {
    var parts = s.toString().split(".");
    if (parts.length > 1) {
        parts[1] = parts[1].substring(0, 2);
        if (parts[1].length < 2)
            parts[1] += "0";
    };
    return parts.join(".");    
  } else
    return s;
}

export default function Chart(props) {
  let component = null;
  const maxWidth = 660;
  let width = maxWidth;
  if (props.rounds !== "undefined" && props.maxRounds !== "undefined") {
    width = Math.round((props.rounds + 1) / props.maxRounds * (maxWidth - 60)) + 60;
  }
  let pWidth = Math.round(width / maxWidth * 100);

  const height = 400;
  const ttField = props.tooltipField ? props.tooltipField : "tooltip";

  const CustomTooltipBarLine = ({ active, payload, label}) => {
    if (active && payload && payload.length && payload[0].payload && payload[0].payload.label) {
      let tt = (payload[0].payload.label[ttField] + "").split("\n");
      let c = tt.map((s, idx) => {
         if (idx > 0) {
           let color = "#777777";
           if (payload.length >= idx)
             color = payload[idx - 1].fill === "#fff" ? payload[idx - 1].stroke : payload[idx - 1].fill;

           return (<Box key={"x"+Math.round(Math.random()*1000)+"_"+Math.round(Math.random()*100)}>
                     <Sign sx={{backgroundColor : color}}></Sign>{fmt(s)}
                   </Box>);
         } else
           return null;
      })
      return (
        <Box sx={{p : 1, backgroundColor: "#FFFFFFD0", borderRadius: 2}}>
          {c}
        </Box>
      );
    }

    return null;
  };

  const CustomTooltipStackedBar = ({ active, payload, label}) => {
    if (active && payload && payload.length && payload[0].payload && payload[0].payload.label) {
      let tt = (payload[0].payload.label[ttField] + "").split("\n");
      tt.shift(); // removing first item which is empty line
      let total = 0;
      let c = tt.map((s, idx) => {
         if (total !== "undefined")
           try {
              let n = s.split(":")[1].trim()*1;
              if (n !== "undefined")
                total += n;
           } catch (err) {
              total = "undefined";
           }
         let color = "#777777";
         if (payload.length > idx)
            color = payload[idx].fill;
         return (<Box key={"x"+Math.round(Math.random()*1000)+"_"+Math.round(Math.random()*100)}>
                   <Sign sx={{backgroundColor : color}}></Sign>{fmt(s)}
                 </Box>);
      })
      c.reverse();
      if (total !== "undefied")
        c.splice(0, 0, 
          <Box key={"x"+Math.round(Math.random()*1000)+"_"+Math.round(Math.random()*100)}>{i18n.t("lTotal") + ": " + fmt(total)}</Box>
          );
      return (
        <Box sx={{p : 1, backgroundColor: "#FFFFFFD0", borderRadius: 2}}>
          {c}
        </Box>
      );
    }

    return null;
  };
 
  const renderLegend = (p) => {
    return (
      <ul>
        {
          props.data.map((entry, index) => (
            <li key={`item-${index}`} style={{color : entry.label.color}}>{entry.label.tooltip}</li>
          ))
        }
      </ul>
    );
  }

  const renderCustomizedLabel2 = (props) => {
    const { x, y, value } = props;
    if (isNaN(x) || isNaN(y)) return;
    return (
      <g>
        <text x={x - 10} y={y - 15} fill="#070707">
          {value}
        </text>
      </g>
    );
  };


  let colorScheme = props.colorScheme;
  if (colorScheme == null)
    colorScheme = 0;

  if (props.type === "scatter") {
    component = (
          <ScatterChart
            width={width}
            height={height}
            margin={{
              top: 40,
              right: 20,
              bottom: 20,
              left: 30,
            }}
          >
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5"  />
            <XAxis type="number" dataKey="x" name={props.xname} label={{value: props.xname, offset: 0, position: 'bottom'}} domain={[0, 'auto']} />
            <YAxis type="number" dataKey="y" name={props.yname} label={{value: props.yname, position: 'insideTopLeft', offset: -28}} domain={[0, 'auto']} />
            <ZAxis type="number" dataKey="z" name={props.zname} range={[200,800]} />
            <Scatter name={props.name} data={props.data} fill="rgba(255,255,255,0.1)">
                {
                  props.data.map((entry, index) => {
                    let fcolor = entry.label.color + "22";
                    return (<Cell key={`cell-${index}`} stroke={entry.label.color} fill={fcolor} strokeWidth={2} ></Cell>);
                  })
                }
            </Scatter>
            <Legend layout="vertical" verticalAlign="top" align="right" content={renderLegend} width={200}/>
            <Tooltip />
          </ScatterChart>
    );
  } else if (props.type === "line") {
        let lines = null;
        let yf = props.yFields;
        if (!Array.isArray(props.yFields))
          yf = [yf];
        lines = yf.map((y, idx) => {
          let color = helper.getColorByIndex2(colorScheme, idx);
          return (<Line type="monotone" key={"line_"+idx} name={props.name} dataKey={y} stroke={color} strokeWidth={3}>
                    <LabelList dataKey="tooltip" content={renderCustomizedLabel2}/>
                  </Line>)
        });

        let yname = props.yname;
        if (!Array.isArray(yname))
          yname = [yname];
        let ynames = yname.map((yn) => {return "  " + yn});

        component = (
          <LineChart
            data={props.data}
            width={width}
            height={height}
            margin={{
              top: 40,
              right: 20,
              bottom: 20,
              left: 30,
            }}
          >
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="x" name={props.xname} label={{value: props.xname, position: 'bottom'}} />
            <YAxis label={{value: ynames, position: 'insideTopLeft', offset: -28 }} />
            { lines }
            <Tooltip cursor={{fill: "#AAAAAA33"}} content={<CustomTooltipBarLine />} />
          </LineChart>
    )
   } else if (props.type === "bar" || props.type === "stackedBar") {
        let bars = null;
        let yf = props.yFields;
        if (!Array.isArray(props.yFields))
          yf = [yf];
        bars = yf.map((y, idx) => {
          let color = helper.getColorByIndex2(colorScheme, idx);
          return (<Bar key={"bar_"+idx} type="monotone" name={props.name} dataKey={y} 
                       fill={color} 
                       stackId={props.type==="stackedBar"?"a":null}>
                    <LabelList dataKey="tooltip" content={renderCustomizedLabel2} />
                  </Bar>)
        });
        let refLine = null;
        if (props.refLine) {
          refLine = <ReferenceLine y={props.refLine} label={{ value: props.refTitle, position: "top" }} stroke="red" strokeDasharray="4 4" />
        }

        let yname = props.yname;
        if (!Array.isArray(yname))
          yname = [yname];
        let ynames = yname.map((yn) => {return "  " + yn});

        component = (
              <BarChart
                data={props.data}
                width={width}
                height={height}
                margin={{
                  top: 40,
                  right: 20,
                  bottom: 20,
                  left: 30,
                }}
              >
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="x" name={props.xname} label={{value: props.xname, position: 'bottom'}} />
                <YAxis label={{value: ynames, position: 'insideTopLeft', offset: -28 }} />
                {bars}
                {refLine}
                <Tooltip cursor={{fill: "#AAAAAA33"}} content={props.type==="stackedBar"?<CustomTooltipStackedBar/>:<CustomTooltipBarLine/>} />
              </BarChart>
        )
    } else if (props.type === "radar") {
      let radars = null;
      let rf = props.rFields;
      radars = rf.map((r, idx) => {
        let color = helper.getColorByIndex2(colorScheme, idx);
        return (<Radar key={"bar_"+idx} type="monotone" name={props.name} dataKey={"value"} 
                     fill={color}
                     fillOpacity={0.1}>
                </Radar>)
      });
      component = (
          <RadarChart
            data={props.data}
            width={width}
            height={height}
            margin={{
              top: 40,
              right: 20,
              bottom: 20,
              left: 30,
            }}
          >
            <PolarGrid stroke="#ccc" strokeDasharray="5 5" />
            <PolarAngleAxis dataKey="key" />
            <PolarRadiusAxis angle={90} />
            {radars}           
          </RadarChart>
    )
  } else if (props.type === "budget") {
      component = (
          <GChart
            chartType={"Sankey"}
            data={props.data}
            style={{
              paddingTop : "10px"
            }}
            options={{
              height: height-20, 
              width: "100%",
              tooltip: {
                isHtml: false,
                textStyle: {
                 fontSize : 11,
                 fontName : "Montserrat",                      
                }
              },
              sankey: {
                iterations: 0,
                link: { 
                  interactivity: false,
                  colorMode: 'target',
                  linkPadding: 80,
                },
                node: {
                  interactivity: false,
                  colorMode: "unique",
                  colors: props.colors,
                  nodePadding: 20,
                  width : 0,
                  labelPadding: 20,
                  label: {
                     fontSize : 14,
                     fontName : "Montserrat",
                     color: "#000000",
                  }
                }
              }
            }}
          />
    )
  }
  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12} lg={12}>
          <h3>
              {props.name}
          </h3>
          <ResponsiveContainer width={pWidth + "%"} height={height}>
            {component}
          </ResponsiveContainer>
      </Grid>
    </React.Fragment>
  );
}
