import React, { useState } from "react";
import { Button, Dialog, TextField, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import AlertMessage from './AlertMessage.js';
import i18n from './i18n.js';
import y42 from './y42.js';

const ChangePassword = props => {
  const [alert, setAlert] = useState({open: false, severity: "info", message: ''});

  var oldP = "";
  var newP = "";
  var repP = "";

/*  const handleOld = (event) => {
  	oldP = event.target.value;
  }
*/
  const handleNew = (event) => {
  	newP = event.target.value;
  }

  const handleRep = (event) => {
  	repP = event.target.value;
  }

  const save = async () => {
  	let pass = {
  		oldPassword : oldP,
  		newPassword : newP,
  		repPassword : repP
  	};
  	let res = await y42.put("changePassword", "", JSON.stringify(pass));
  	if (res._status !== y42.ok)
  		setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
  	else {
  		await y42.login(y42.getUser().name, newP);
  		setAlert({open: true, severity: "info", message: "Password changed successfully"});
  		handleClose();
  	}
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
  	<>
      <Dialog open={props.open} onClose={handleClose} width="100ch">
		    <DialogTitle id="title">{i18n.t("tChangePassword")}</DialogTitle>
		    <DialogContent>
	        <TextField
				      sx={{ml: 1, mr: 1}}
	            id="new"
	            key="new"
	            name="new"
				      label={i18n.t("fNewPassword")} 
				      margin="dense"
	            variant="outlined"
	            type="password"
	            onChange={handleNew}
	         />
	        <TextField
              sx={{ml: 1, mr: 1}}
	            id="rep"
	            key="rep"
	            name="rep"
				      label={i18n.t("fRepeatNewPassword")} 
				      margin="dense"
	            variant="outlined"
	            type="password"
	            onChange={handleRep}
	         />
     	 </DialogContent>
     	 <DialogActions>
         	<Button color="primary" variant="contained" type="submit" onClick={save}>{i18n.t("bChangePassword")}</Button>
         	<Button onClick={handleClose}>{i18n.t("bCancel")}</Button>
         </DialogActions>
      </Dialog>
      <AlertMessage open={alert.open} severity={alert.severity} message={alert.message}/>
    </>
  );
};

export default ChangePassword;