import React from 'react';
import SignInSide from './components/SignInSide.js';
import SignUp from './components/SignUp.js';
import Admin from './components/Admin.js';
import Home from './components/Home.js';
import Manage from './components/Manage.js';

import PrintReport from './components/PrintReport.js';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

export default function App() {
  return (
     <Router forceRefresh={true}>
        <Routes>
           <Route key="a404" path="*" element={<SignInSide />}/>
           <Route path="/" exact element={<SignInSide />} />
           <Route path="/signup/:code" element={<SignUp />} />
           <Route path="/signup" element={<SignUp />} />
           <Route path="/admin/*" element={<Admin />}/>
           <Route path="/manage/*" element={<Manage />} />
           <Route path="/home/*" element={<Home />} />
           <Route key="printReport" exact path="/printReport/:gameId/:playerId/:step" element={<PrintReport />} />
        </Routes>
     </Router>
  );
}
