import React, { useState } from 'react';

export default function Timer(props) {
	const [timer, setTimer] = useState(props.time);
    var time = new Date();

    const handleTimer = function () {
      let currentTime = new Date();
      if (timer != null && timer > 0.5) {
         let diff = (currentTime.getTime() - time.getTime()) / 1000;
         setTimer(timer - diff);
         time = currentTime;
      } else
          if (timer != null && timer <= 0.5 && props.callback) {
            props.callback();
            setTimer(props.time);
        }
    }

    if (timer >= -1)
    	setTimeout(() => {
            handleTimer();
        }, props.interval);

    return (<></>);
}