import React from 'react';
import { Routes, Route } from "react-router-dom";
import BasicPage from './BasicPage.js';
import Accounts from './Accounts.js';
import Simulations from './Simulations.js';
import Users from './Users.js';
import Games from './Games.js';
import Players from './Players.js';
import RunGame from './RunGame.js';
import Dashboard from './Dashboard.js';
import i18n from './i18n.js';
import y42 from './y42.js';

export default function Admin() {
  const menuItems = [
      {icon: "home", text: i18n.t("mDashboard"), link: "/admin"},
      {icon: "domain", text: i18n.t("mAccounts"), link: "/admin/accounts"},
      {icon: "people", text: i18n.t("mUsers"), link: "/admin/users"},
      {icon: "track_changes", text: i18n.t("mSimulations"), link: "/admin/simulations"},
      {icon: "timer", text: i18n.t("mGames"), link: "/admin/games"},
      {icon: "home", text: i18n.t("mAsUser"), link: "/home", divider: true}
  ];

  let inner = ( 
    <Routes>
      <Route key="admin" path="/*" element={<Dashboard />}/>
      <Route key="simulations" path="simulations/*" element={<Simulations/>} />
      <Route key="simulation" exact path="simulations/:id/*" element={<Simulations/>} />
      <Route key="users" path="users/*" element={<Users/>} />
      <Route key="user" path="users/:id/*" element={<Users/>} />
      <Route key="accounts" exact path="accounts/" element={<Accounts/>} />
      <Route key="account" exact path="accounts/:id" element={<Accounts/>} />
      <Route key="games" exact path="games" element={<Games/>} />
      <Route key="game" exact path="games/:id" element={<Games/>} />
      <Route key="run" exact path="games/:id/run" element={<RunGame/>} />
      <Route key="player" exact path="games/:fid/players/:id" element={<Players/>} />
      <Route key="players" exact path="games/:fid/players" element={<Players/>} />
      <Route key="home" path="../home/*" element={<Users/>} />
    </Routes>
  );        

  let user = y42.getUser();
  if (user && user.role && user.role.name !== "ROLE_ADMIN")
    return "";
  else
    return <BasicPage title={i18n.t('tAdministrativePanel')} menuItems={menuItems} routesComponents={inner} />;
}